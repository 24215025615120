import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import OrgCustomersTab from './OrgCustomersTab'
import OrgMembersTab from './OrgMembersTab'
import OrgInfoTab from './OrgInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { compile } from 'path-to-regexp'
import { useOrg } from '@/lib/db'
import { useCurrentOrg } from '../auth/UserProvider'
import { OrgRef } from '@/lib/types'

interface MatchParams extends OrgRef {
  tabId: string | undefined
}

export default function OrgPage() {
  const currentOrg = useCurrentOrg()
  const { path, params } = useRouteMatch<MatchParams>()
  const { tabId = 'info' } = params
  const baseUrl = compile(path)({ ...params, tabId: undefined })
  const history = useHistory()

  const [org, loading /*, error */] = useOrg({ ...params, orgId: currentOrg.id })

  if (loading) return <></>

  if (!org) return <NotFoundPage />

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{org.name}</h1>
      <div role="tablist" className="tabs tabs-bordered md:inline-block">
        <input
          type="radio"
          name="org_tabs"
          role="tab"
          className="tab"
          value="info"
          aria-label="Info"
          onChange={() => {
            history.push(`${baseUrl}`)
          }}
          checked={tabId === 'info'}
        />
        <input
          type="radio"
          name="org_tabs"
          role="tab"
          className="tab"
          value="customers"
          aria-label="Customers"
          onChange={async () => {
            history.push(`${baseUrl}/customers`)
          }}
          checked={tabId === 'customers'}
        />
        <input
          type="radio"
          name="org_tabs"
          role="tab"
          className="tab"
          value="members"
          aria-label="Members"
          onChange={async () => {
            history.push(`${baseUrl}/members`)
          }}
          checked={tabId === 'members'}
        />
      </div>
      <div className="pt-10">
        <Switch>
          <Route exact path={`${baseUrl}`}>
            <OrgInfoTab org={org} />
          </Route>
          <Route exact path={`${baseUrl}/customers`}>
            <OrgCustomersTab org={org} />
          </Route>
          <Route exact path={`${baseUrl}/members`}>
            <OrgMembersTab org={org} />
          </Route>
        </Switch>
      </div>
    </>
  )
}
