/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFirestore } from '@/components/FirebaseProvider'
import {
  Building,
  BuildingRef,
  BuildingSchema,
  Customer,
  CustomerMember,
  CustomerMemberRef,
  CustomerMemberSchema,
  CustomerRef,
  CustomerSchema,
  Device,
  DeviceImage,
  DeviceImageRef,
  DeviceImageSchema,
  DeviceRef,
  DeviceSchema,
  DeviceVersion,
  DeviceVersionRef,
  DeviceVersionSchema,
  Favorite,
  FavoriteDevice,
  FavoriteDeviceRef,
  FavoriteDeviceSchema,
  FavoriteRef,
  FavoriteSchema,
  FavoriteTask,
  FavoriteTaskRef,
  FavoriteTaskSchema,
  Floor,
  FloorRef,
  FloorSchema,
  Location,
  LocationRef,
  LocationSchema,
  Org,
  OrgMember,
  OrgMemberRef,
  OrgMemberSchema,
  OrgRef,
  OrgSchema,
  Project,
  ProjectFloor,
  ProjectFloorRef,
  ProjectFloorSchema,
  ProjectMember,
  ProjectMemberRef,
  ProjectMemberSchema,
  ProjectRef,
  ProjectSchema,
  ServiceTicket,
  ServiceTicketComment,
  ServiceTicketCommentRef,
  ServiceTicketCommentSchema,
  ServiceTicketFileAttachment,
  ServiceTicketFileAttachmentRef,
  ServiceTicketFileAttachmentSchema,
  ServiceTicketRef,
  ServiceTicketSchema,
  ServiceTicketWatcher,
  ServiceTicketWatcherRef,
  ServiceTicketWatcherSchema,
  Site,
  SiteMember,
  SiteMemberRef,
  SiteMemberSchema,
  SiteRef,
  SiteSchema,
  Task,
  TaskImage,
  TaskImageRef,
  TaskImageSchema,
  TaskRef,
  TaskSchema,
  User,
  UserCustomerPreferences,
  UserCustomerPreferencesRef,
  UserCustomerPreferencesSchema,
  UserFloorPreferences,
  UserFloorPreferencesRef,
  UserFloorPreferencesSchema,
  UserRef,
  UserSchema,
} from './types'
import {
  collection,
  doc,
  QueryConstraint,
  setDoc,
  Firestore,
  DocumentData,
  FirestoreDataConverter,
  FirestoreError,
  QueryDocumentSnapshot,
  QuerySnapshot,
  onSnapshot,
  query,
} from 'firebase/firestore'
import { useState, useEffect, useMemo } from 'react'
import { ZodObject } from 'zod'
import { compile } from 'path-to-regexp'

// Generic Repository
function getConverter<T>(schema: ZodObject<any>) {
  return {
    toFirestore: (d: T) => {
      schema.parse(d)
      return d as DocumentData
    },
    fromFirestore: (s: QueryDocumentSnapshot) => {
      return s.data() as T
    },
  }
}

function getUseDoc<TRef, T>(makeDocPath: (refOrDoc: TRef | T) => string, converter: FirestoreDataConverter<T>) {
  return (ref: TRef): [T | undefined, boolean, FirestoreError | undefined] => {
    const firestore = useFirestore()
    const [docx, setDoc] = useState<T | undefined>(undefined)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<FirestoreError | undefined>(undefined)

    useEffect(() => {
      const docPath = makeDocPath(ref)
      const docRef = doc(firestore, docPath).withConverter(converter)
      const unsubscribe = onSnapshot(
        docRef,
        (snapshot) => {
          const doc = snapshot.data()
          setDoc(doc)
          setLoading(false)
        },
        (error: FirestoreError) => {
          setError(error)
          setLoading(false)
        },
      )

      return () => unsubscribe()
    }, [firestore, ref])

    return [docx, loading, error]
  }
}

function getSaveDoc<TRef, T>(makeDocPath: (refOrDoc: TRef | T) => string, converter: FirestoreDataConverter<T>) {
  return async (firestore: Firestore, docx: T) => {
    const docPath = makeDocPath(docx)
    const docRef = doc(firestore, docPath).withConverter(converter)
    await setDoc(docRef, docx)
  }
}

function getUseCollection<T>(collectionPath: string, converter: FirestoreDataConverter<T>) {
  return (...queryConstraints: QueryConstraint[]): [T[] | undefined, boolean, FirestoreError | undefined] => {
    const firestore = useFirestore()
    const [docs, setDocs] = useState<T[] | undefined>(undefined)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<FirestoreError | undefined>(undefined)

    // since the querycontraints are reconstructed each time, we need to memoize them
    // but use a JSON string representation for comparison
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedQueryConstraints = useMemo(() => queryConstraints, [JSON.stringify(queryConstraints)])

    useEffect(() => {
      const collectionRef = collection(firestore, collectionPath).withConverter(converter)
      const q = query(collectionRef, ...queryConstraints)
      const unsubscribe = onSnapshot(
        q,
        (snapshot: QuerySnapshot<T>) => {
          const docs = snapshot.docs.map((doc) => doc.data())
          setDocs(docs)
          setLoading(false)
        },
        (error: FirestoreError) => {
          setError(error)
          setLoading(false)
        },
      )

      return () => unsubscribe()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firestore, memoizedQueryConstraints])

    return [docs, loading, error]
  }
}

export function createRootRepository<TRef extends Record<string, string>, T extends { id: string }>(
  schema: ZodObject<any>,
  pathTemplate: string,
) {
  // extract the collection path and the document key variable
  const parts = pathTemplate.split('/')
  const documentKey = parts[parts.length - 1].substring(1) // remove the colon
  const collectionPathTemplate = parts.slice(0, -1).join('/')
  const makeDocPath = (refOrDoc: TRef | T): string => {
    const id = 'id' in refOrDoc ? refOrDoc.id : refOrDoc[documentKey]
    return `${collectionPathTemplate}/${id}`
  }

  const converter = getConverter<T>(schema)
  const useDoc = getUseDoc<TRef, T>(makeDocPath, converter)
  const saveDoc = getSaveDoc<TRef, T>(makeDocPath, converter)

  const useCollection = (
    ...queryConstraints: QueryConstraint[]
  ): [T[] | undefined, boolean, FirestoreError | undefined] => {
    return getUseCollection(collectionPathTemplate, converter)(...queryConstraints)
  }

  return {
    converter,
    makeDocPath,
    useCollection,
    useDoc,
    saveDoc,
  }
}

export function createRepository<
  TRef extends TParentRef,
  T extends TParentRef,
  TParentRef extends object,
  TParent extends object,
>(schema: ZodObject<any>, pathTemplate: string) {
  // split the path into parts by '/'
  const parts = pathTemplate.split('/')

  // pull out the key for object type and create the function
  // to make the path from a ref or doc
  const key = parts[parts.length - 1].substring(1) // remove the colon
  const makeDocPathFn = compile<TRef | T>(pathTemplate)
  const makeDocPath = (refOrDoc: TRef | T): string => {
    const params = 'id' in refOrDoc ? { ...refOrDoc, [key]: refOrDoc.id } : refOrDoc
    return makeDocPathFn(params)
  }

  const collectionPathTemplate = parts.slice(0, -1).join('/')
  const parentKey = parts[parts.length - 3].substring(1) // remove the colon
  const makeCollectionPathFn = compile<TParentRef | TParent>(collectionPathTemplate)
  const makeCollectionPath = (parentRefOrDoc: TParentRef | TParent): string => {
    const params = 'id' in parentRefOrDoc ? { ...parentRefOrDoc, [parentKey]: parentRefOrDoc.id } : parentRefOrDoc
    return makeCollectionPathFn(params)
  }

  const converter = getConverter<T>(schema)
  const useDoc = getUseDoc<TRef, T>(makeDocPath, converter)
  const saveDoc = getSaveDoc<TRef, T>(makeDocPath, converter)
  const useCollection = (
    parentRefOrDoc: TParentRef | TParent,
    ...queryConstraints: QueryConstraint[]
  ): [T[] | undefined, boolean, FirestoreError | undefined] => {
    const collectionPath = makeCollectionPath(parentRefOrDoc)
    return getUseCollection(collectionPath, converter)(...queryConstraints)
  }

  return {
    converter,
    makeDocPath,
    useCollection,
    useDoc,
    saveDoc,
  }
}

// Orgs
const orgRepository = createRootRepository<OrgRef, Org>(OrgSchema, 'orgs/:orgId')
export const {
  makeDocPath: makeOrgPath,
  useCollection: useOrgs,
  useDoc: useOrg,
  saveDoc: setOrg,
  converter: orgConverter,
} = orgRepository

// OrgMembers
const orgMemberRepository = createRepository<OrgMemberRef, OrgMember, OrgRef, Org>(
  OrgMemberSchema,
  'orgs/:orgId/members/:userId',
)
export const {
  makeDocPath: makeOrgMemberPath,
  useCollection: useOrgMembers,
  useDoc: useOrgMember,
  saveDoc: setOrgMember,
} = orgMemberRepository

// Customers
const customerRepository = createRepository<CustomerRef, Customer, OrgRef, Org>(
  CustomerSchema,
  'orgs/:orgId/customers/:customerId',
)
export const {
  converter: customerConverter,
  makeDocPath: makeCustomerPath,
  useCollection: useCustomers,
  useDoc: useCustomer,
  saveDoc: setCustomer,
} = customerRepository

// CustomerMembers
const customerMemberRepository = createRepository<CustomerMemberRef, CustomerMember, CustomerRef, Customer>(
  CustomerMemberSchema,
  'orgs/:orgId/customers/:customerId/members/:userId',
)
export const {
  makeDocPath: makeCustomerMemberPath,
  useCollection: useCustomerMembers,
  useDoc: useCustomerMember,
  saveDoc: setCustomerMember,
} = customerMemberRepository

// Projects
const projectRepository = createRepository<ProjectRef, Project, CustomerRef, Customer>(
  ProjectSchema,
  'orgs/:orgId/customers/:customerId/projects/:projectId',
)
export const {
  makeDocPath: makeProjectPath,
  useCollection: useProjects,
  useDoc: useProject,
  saveDoc: setProject,
} = projectRepository

// ProjectMembers
const projectMemberRepository = createRepository<ProjectMemberRef, ProjectMember, ProjectRef, Project>(
  ProjectMemberSchema,
  'orgs/:orgId/customers/:customerId/projects/:projectId/members/:userId',
)

export const {
  makeDocPath: makeProjectMemberPath,
  useCollection: useProjectMembers,
  useDoc: useProjectMember,
  saveDoc: setProjectMember,
} = projectMemberRepository

// ProjectFloors
const projectFloorRepository = createRepository<ProjectFloorRef, ProjectFloor, ProjectRef, Project>(
  ProjectFloorSchema,
  'orgs/:orgId/customers/:customerId/projects/:projectId/floors/:floorId',
)
export const {
  makeDocPath: makeProjectFloorPath,
  useCollection: useProjectFloors,
  useDoc: useProjectFloor,
  saveDoc: setProjectFloor,
} = projectFloorRepository

// ServiceTickets
const serviceTicketRepository = createRepository<ServiceTicketRef, ServiceTicket, CustomerRef, Customer>(
  ServiceTicketSchema,
  'orgs/:orgId/customers/:customerId/service_tickets/:serviceTicketId',
)
export const {
  makeDocPath: makeServiceTicketPath,
  useCollection: useServiceTickets,
  useDoc: useServiceTicket,
  saveDoc: setServiceTicket,
} = serviceTicketRepository

// ServiceTicketWatchers
const serviceTicketWatcherRepository = createRepository<
  ServiceTicketWatcherRef,
  ServiceTicketWatcher,
  ServiceTicketRef,
  ServiceTicket
>(ServiceTicketWatcherSchema, 'orgs/:orgId/customers/:customerId/service_tickets/:serviceTicketId/watchers/:userId')
export const {
  makeDocPath: makeServiceTicketWatcherPath,
  useCollection: useServiceTicketWatchers,
  useDoc: useServiceTicketWatcher,
  saveDoc: setServiceTicketWatcher,
} = serviceTicketWatcherRepository

// ServiceTicketComments
const serviceTicketCommentRepository = createRepository<
  ServiceTicketCommentRef,
  ServiceTicketComment,
  ServiceTicketRef,
  ServiceTicket
>(
  ServiceTicketCommentSchema,
  'orgs/:orgId/customers/:customerId/service_tickets/:serviceTicketId/comments/:serviceTicketCommentId',
)
export const {
  makeDocPath: makeServiceTicketCommentPath,
  useCollection: useServiceTicketComments,
  useDoc: useServiceTicketComment,
  saveDoc: setServiceTicketComment,
} = serviceTicketCommentRepository

// ServiceTicketFileAttachments
const serviceTicketFileAttachmentRepository = createRepository<
  ServiceTicketFileAttachmentRef,
  ServiceTicketFileAttachment,
  ServiceTicketRef,
  ServiceTicket
>(
  ServiceTicketFileAttachmentSchema,
  'orgs/:orgId/customers/:customerId/service_tickets/:serviceTicketId/files/:serviceTicketFileAttachmentId',
)
export const {
  makeDocPath: makeServiceTicketFileAttachmentPath,
  useCollection: useServiceTicketFileAttachments,
  useDoc: useServiceTicketFileAttachment,
  saveDoc: setServiceTicketFileAttachment,
} = serviceTicketFileAttachmentRepository

// Sites
const siteRepository = createRepository<SiteRef, Site, CustomerRef, Customer>(
  SiteSchema,
  'orgs/:orgId/customers/:customerId/sites/:siteId',
)
export const { makeDocPath: makeSitePath, useCollection: useSites, useDoc: useSite, saveDoc: setSite } = siteRepository

// SiteMembers
const siteMemberRepository = createRepository<SiteMemberRef, SiteMember, CustomerRef, Customer>(
  SiteMemberSchema,
  'orgs/:orgId/customers/:customerId/sites/:siteId/members/:userId',
)
export const {
  makeDocPath: makeSiteMemberPath,
  useCollection: useSiteMembers,
  useDoc: useSiteMember,
  saveDoc: setSiteMember,
} = siteMemberRepository

// Buildings
const buildingRepository = createRepository<BuildingRef, Building, SiteRef, Site>(
  BuildingSchema,
  'orgs/:orgId/customers/:customerId/sites/:siteId/buildings/:buildingId',
)
export const {
  makeDocPath: makeBuildingPath,
  useCollection: useBuildings,
  useDoc: useBuilding,
  saveDoc: setBuilding,
} = buildingRepository

// Floors
const floorRepository = createRepository<FloorRef, Floor, BuildingRef, Building>(
  FloorSchema,
  'orgs/:orgId/customers/:customerId/sites/:siteId/buildings/:buildingId/floors/:floorId',
)
export const {
  makeDocPath: makeFloorPath,
  useCollection: useFloors,
  useDoc: useFloor,
  saveDoc: setFloor,
} = floorRepository

// Locations
const locationRepository = createRepository<LocationRef, Location, FloorRef, Floor>(
  LocationSchema,
  'orgs/:orgId/customers/:customerId/sites/:siteId/buildings/:buildingId/floors/:floorId/locations/:locationId',
)
export const {
  makeDocPath: makeLocationPath,
  useCollection: useLocations,
  useDoc: useLocation,
  saveDoc: setLocation,
} = locationRepository

// Devices
const deviceRepository = createRepository<DeviceRef, Device, FloorRef, Floor>(
  DeviceSchema,
  'orgs/:orgId/customers/:customerId/sites/:siteId/buildings/:buildingId/floors/:floorId/devices/:deviceId',
)
export const {
  makeDocPath: makeDevicePath,
  useCollection: useDevices,
  useDoc: useDevice,
  saveDoc: setDevice,
} = deviceRepository

// DeviceVersions
const deviceVersionRepository = createRepository<DeviceVersionRef, DeviceVersion, DeviceRef, Device>(
  DeviceVersionSchema,
  'orgs/:orgId/customers/:customerId/sites/:siteId/buildings/:buildingId/floors/:floorId/devices/:deviceId/versions/:deviceVersionId',
)
export const {
  makeDocPath: makeDeviceVersionPath,
  useCollection: useDeviceVersions,
  useDoc: useDeviceVersion,
  saveDoc: setDeviceVersion,
} = deviceVersionRepository

// DeviceImages
const deviceImageRepository = createRepository<DeviceImageRef, DeviceImage, DeviceRef, Device>(
  DeviceImageSchema,
  'orgs/:orgId/customers/:customerId/sites/:siteId/buildings/:buildingId/floors/:floorId/devices/:deviceId/images/:deviceImageId',
)
export const {
  makeDocPath: makeDeviceImagePath,
  useCollection: useDeviceImages,
  useDoc: useDeviceImage,
  saveDoc: setDeviceImage,
} = deviceImageRepository

// Tasks
const taskRepository = createRepository<TaskRef, Task, FloorRef, Floor>(
  TaskSchema,
  'orgs/:orgId/customers/:customerId/sites/:siteId/buildings/:buildingId/floors/:floorId/tasks/:taskId',
)
export const { makeDocPath: makeTaskPath, useCollection: useTasks, useDoc: useTask, saveDoc: setTask } = taskRepository

// TaskImages
const taskImageRepository = createRepository<TaskImageRef, TaskImage, TaskRef, Task>(
  TaskImageSchema,
  'orgs/:orgId/customers/:customerId/sites/:siteId/buildings/:buildingId/floors/:floorId/tasks/:taskId/images/:taskImageId',
)

export const {
  makeDocPath: makeTaskImagePath,
  useCollection: useTaskImages,
  useDoc: useTaskImage,
  saveDoc: setTaskImage,
} = taskImageRepository

// Favorites
const favoriteRepository = createRepository<FavoriteRef, Favorite, CustomerRef, Customer>(
  FavoriteSchema,
  'orgs/:orgId/customers/:customerId/favorites/:favoriteId',
)
export const {
  makeDocPath: makeFavoritePath,
  useCollection: useFavorites,
  useDoc: useFavorite,
  saveDoc: setFavorite,
} = favoriteRepository

// FavoriteDevices
const favoriteDeviceRepository = createRepository<FavoriteDeviceRef, FavoriteDevice, FavoriteRef, Favorite>(
  FavoriteDeviceSchema,
  'orgs/:orgId/customers/:customerId/favorites/:favoriteId/favorite_devices/:favoriteDeviceId',
)
export const {
  makeDocPath: makeFavoriteDevicePath,
  useCollection: useFavoriteDevices,
  useDoc: useFavoriteDevice,
  saveDoc: setFavoriteDevice,
} = favoriteDeviceRepository

// FavoriteTasks
const favoriteTaskRepository = createRepository<FavoriteTaskRef, FavoriteTask, FavoriteRef, Favorite>(
  FavoriteTaskSchema,
  'orgs/:orgId/customers/:customerId/favorites/:favoriteId/favorite_tasks/:favoriteTaskId',
)
export const {
  makeDocPath: makeFavoriteTaskPath,
  useCollection: useFavoriteTasks,
  useDoc: useFavoriteTask,
  saveDoc: setFavoriteTask,
} = favoriteTaskRepository

// User
const userRepository = createRootRepository<UserRef, User>(UserSchema, 'users/:userId')
export const { makeDocPath: makeUserPath, useCollection: useUsers, useDoc: useUser, saveDoc: setUser } = userRepository

// UserCustomerPreferences
const userCustomerPreferencesRepository = createRepository<
  UserCustomerPreferencesRef,
  UserCustomerPreferences,
  UserRef,
  User
>(UserCustomerPreferencesSchema, 'users/:userId/customer_preferences/:customerId')
export const {
  makeDocPath: makeUserCustomerPreferencesPath,
  useCollection: useUserCustomerPreferences,
  useDoc: useUserCustomerPreference,
  saveDoc: setUserCustomerPreferences,
} = userCustomerPreferencesRepository

// UserFloorPreferences
const userFloorPreferencesRepository = createRepository<UserFloorPreferencesRef, UserFloorPreferences, UserRef, User>(
  UserFloorPreferencesSchema,
  'users/:userId/floor_preferences/:floorId',
)
export const {
  makeDocPath: makeUserFloorPreferencesPath,
  useCollection: useUserFloorPreferences,
  useDoc: useUserFloorPreference,
  saveDoc: setUserFloorPreferences,
} = userFloorPreferencesRepository
