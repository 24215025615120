import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import FavoriteDeviceInfoTab from './FavoriteDeviceInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useFavoriteDevice } from '@/lib/db'
import { compile } from 'path-to-regexp'
import { FavoriteDeviceRef } from '@/lib/types'
import { useCurrentOrg } from '../auth/UserProvider'

interface MatchParams extends FavoriteDeviceRef {
  tabId: string | undefined
}

export default function FavoriteDevicePage() {
  const currentOrg = useCurrentOrg()
  const { path, params } = useRouteMatch<MatchParams>()
  const { tabId = 'info' } = params
  const baseUrl = compile(path)({ ...params, tabId: undefined })
  const history = useHistory()

  const [favoriteDevice, loading] = useFavoriteDevice({ ...params, orgId: currentOrg.id })

  if (loading) return <></>

  if (!favoriteDevice) return <NotFoundPage />

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{favoriteDevice.name}</h1>
      <div role="tablist" className="tabs tabs-bordered md:inline-block">
        <input
          type="radio"
          name="favoriteDevice_tabs"
          role="tab"
          className="tab"
          value="info"
          aria-label="Info"
          onChange={() => {
            history.push(`${baseUrl}`)
          }}
          checked={tabId === 'info'}
        />
      </div>
      <div className="pt-10">
        <Switch>
          <Route exact path={`${baseUrl}`}>
            <FavoriteDeviceInfoTab favoriteDevice={favoriteDevice} />
          </Route>
        </Switch>
      </div>
    </>
  )
}
