import { useEffect } from 'react'
import { useUserState } from './UserProvider'
import { useLogin } from './FronteggUserProvider'
import { SplashScreen } from '@capacitor/splash-screen'

export default function AuthGuard({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  const { user, isLoading } = useUserState()
  const login = useLogin()

  useEffect(() => {
    async function hideSplashScreen() {
      console.log(`AUTHGUARD: useEffect isLoading=${isLoading}, userId=${user?.id}`)
      if (isLoading) {
        console.log('AUTHGUARD: still loading, just wait longer')
        return
      }

      console.log(`AUTHGUARD: is loaded, userId=${user?.id}`)

      if (user) {
        console.log('AUTHGUARD: user has loaded and we are logged in')
        await SplashScreen.hide()
        return
      }

      console.log('AUTHGUARD: no user.  we are logged out.  redirect to login')
      login()
      await SplashScreen.hide()
    }

    hideSplashScreen()
  }, [isLoading, login, user])

  // if it's still loading show a spinner
  // if it's done loading and there still isn't a user
  // continue to show the spinner while we wait for a redirect to frontegg
  if (isLoading) {
    console.log(`AUTHGUARD: rendering spinner`)
    return (
      <div className="flex items-center justify-center h-screen">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    )
  }

  console.log(`AUTHGUARD: rendering page`)
  return <>{children}</>
}
