import { Building } from '@/lib/types'

export default function BuildingInfoTab({ building }: { building: Building }) {
  return (
    <table className="border-1 border rounded-lg border-separate border-spacing-0">
      <tbody>
        <tr>
          <td className="rounded-tl-lg border text-right bg-muted p-3">Name</td>
          <td className="rounded-tr-lg border p-3">{building.name}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Address</td>
          <td className="border p-3">{building.address}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Order Number</td>
          <td className="border p-3">{building.orderNumber}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Is Archived</td>
          <td className="rounded-br-lg border p-3">{building.isArchived}</td>
        </tr>
      </tbody>
    </table>
  )
}
