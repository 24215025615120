import { Link } from 'react-router-dom'
import { useProjects } from '@/lib/db'
import { Customer } from '@/lib/types'

export default function CustomerProjectsTab({ customer }: Readonly<{ customer: Customer }>) {
  const [projects, loading] = useProjects(customer)

  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra">
        <thead>
          <tr>
            <th>Project</th>
          </tr>
        </thead>
        <tbody>
          {projects?.map((project) => {
            return (
              <tr key={project.id}>
                <td>
                  <Link className="hover:text-primary" to={`projects/${project.id}`}>
                    {project.name}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
