import { Link } from 'react-router-dom'
import { useProjectFloors } from '@/lib/db'
import { Project } from '@/lib/types'

export default function ProjectFloorsTab({ project }: Readonly<{ project: Project }>) {
  const [projectFloors, loading] = useProjectFloors(project)

  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Floor</th>
          </tr>
        </thead>
        <tbody>
          {projectFloors?.map((projectFloor) => {
            return (
              <tr key={projectFloor.floorId}>
                <td>
                  <Link className="hover:text-primary" to={`floors/${projectFloor.floorId}`}>
                    {projectFloor.floorId}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
