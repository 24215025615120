import { Link } from 'react-router-dom'
import { useLocations } from '@/lib/db'
import { Floor } from '@/lib/types'

export default function FloorDevicesTab({ floor }: Readonly<{ floor: Floor }>) {
  const [locations, loading] = useLocations(floor)

  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Device</th>
          </tr>
        </thead>
        <tbody>
          {locations?.map((location) => {
            return (
              <tr key={location.id}>
                <td>
                  <Link className="hover:text-primary" to={`locations/${location.id}`}>
                    {location.name || location.imageLeft + '/' + location.imageTop}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
