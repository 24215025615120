import * as React from 'react'
import { Link } from 'react-router-dom'
import { useOrgMembers } from '@/lib/db'
import { Org } from '@/lib/types'

export default function OrgMembersTab({ org }: Readonly<{ org: Org }>) {
  const [members, loading] = useOrgMembers(org)

  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra">
        <thead>
          <tr>
            <th>Member</th>
          </tr>
        </thead>
        <tbody>
          {members?.map((member) => {
            return (
              <tr key={member.userId}>
                <td>
                  <Link className="hover:text-primary" to={`members/${member.userId}`}>
                    {member.userId}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
