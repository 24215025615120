import { Capacitor } from '@capacitor/core'
import { FirebaseApp, initializeApp } from 'firebase/app'
import { Auth, connectAuthEmulator, getAuth, initializeAuth, indexedDBLocalPersistence } from 'firebase/auth'
import {
  CACHE_SIZE_UNLIMITED,
  // CACHE_SIZE_UNLIMITED,
  Firestore,
  connectFirestoreEmulator,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  // initializeFirestore,
  // persistentLocalCache,
  // persistentMultipleTabManager,
} from 'firebase/firestore'
import { Functions, connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { FirebaseStorage, connectStorageEmulator, getStorage } from 'firebase/storage'
import MessagingProvider from './MessagingProvider'
import { createContext, useContext, useState } from 'react'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}

// context to hold firebase app, firestore, storage, and functions
type FirebaseContextState = {
  app: FirebaseApp
  auth: Auth
  firestore: Firestore
  storage: FirebaseStorage
  functions: Functions
}

const FirebaseContext = createContext<FirebaseContextState | undefined>(undefined)

// Module-level variable to track the Firestore initialization
let cachedFirestore: Firestore | undefined

export default function FirebaseProvider({
  firestore,
  storage,
  children,
}: Readonly<{
  firestore?: Firestore
  storage?: FirebaseStorage
  children: React.ReactNode
}>) {
  // create the Firebase app and get the services
  const app = initializeApp(firebaseConfig)
  const functions = getFunctions(app)

  if (!storage) storage = getStorage(app)

  // initialize the firestore with a local cache
  firestore = cachedFirestore
  if (!firestore) {
    firestore = initializeFirestore(app, {
      localCache: persistentLocalCache({
        tabManager: persistentMultipleTabManager(),
        cacheSizeBytes: CACHE_SIZE_UNLIMITED,
      }),
    })
    cachedFirestore = firestore
  }

  // this bridges the native firebase auth for iOS and Android to the web
  let auth: Auth
  if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
    })
  } else {
    auth = getAuth(app)
  }

  // connect to the emulators if needed
  if (import.meta.env.VITE_USE_EMULATORS === 'true' || process.env.NODE_ENV === 'test') {
    console.log('connecting to emulators')
    try {
      connectAuthEmulator(auth, process.env.NODE_ENV === 'test' ? 'http://127.0.0.1:9098' : 'http://127.0.0.1:9099', {
        disableWarnings: true,
      })
      connectFunctionsEmulator(functions, '127.0.0.1', process.env.NODE_ENV === 'test' ? 5002 : 5001)
      connectStorageEmulator(storage, '127.0.0.1', process.env.NODE_ENV === 'test' ? 9198 : 9199)
      const firestorePort = process.env.NODE_ENV === 'test' ? 8082 : 8080

      connectFirestoreEmulator(firestore, '127.0.0.1', firestorePort)
      // if ((firestore as any)._delegate._settings.host !== `127.0.0.1:${firestorePort}`) {
      //   connectFirestoreEmulator(firestore, '127.0.0.1', firestorePort)
      // }
    } catch (e) {
      // this code throws if it tries to run more than once
    }
  }

  // store the services in the state
  const [firebaseState] = useState<FirebaseContextState>({
    app,
    auth,
    firestore,
    storage,
    functions,
  })

  return (
    <FirebaseContext.Provider value={firebaseState}>
      <MessagingProvider>{children}</MessagingProvider>
    </FirebaseContext.Provider>
  )
}

export function useApp() {
  return useContext(FirebaseContext)?.app as FirebaseApp
}

export function useAuth() {
  return useContext(FirebaseContext)?.auth as Auth
}

export function useFirestore() {
  return useContext(FirebaseContext)?.firestore as Firestore
}

export function useFunctions() {
  return useContext(FirebaseContext)?.functions as Functions
}

export function useStorage() {
  return useContext(FirebaseContext)?.storage as FirebaseStorage
}
