import { Location } from '@/lib/types'

export default function LocationInfoTab({ location }: { location: Location }) {
  return (
    <>
      <table className="border-1 border rounded-lg border-separate border-spacing-0">
        <tbody>
          <tr>
            <td className="rounded-tl-lg border text-right bg-muted p-3">Name</td>
            <td className="rounded-tr-lg border p-3">{location.name}</td>
          </tr>
          <tr>
            <td className="border text-right bg-muted p-3">Image Left</td>
            <td className="border p-3">{location.imageLeft}</td>
          </tr>
          <tr>
            <td className="border text-right bg-muted p-3">Image Top</td>
            <td className="border p-3">{location.imageTop}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
