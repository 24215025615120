import { Link } from 'react-router-dom'
import { useServiceTicketComments } from '@/lib/db'
import { ServiceTicket } from '@/lib/types'

export default function ServiceTicketCommentsTab({ serviceTicket }: Readonly<{ serviceTicket: ServiceTicket }>) {
  const [comments, loading] = useServiceTicketComments(serviceTicket)

  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {comments?.map((comment) => {
            return (
              <tr key={comment.id}>
                <td>
                  <Link className="hover:text-primary" to={`comments/${comment.id}`}>
                    {comment.comment}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
