import { Floor } from '@/lib/types'

export default function FloorInfoTab({ floor }: { floor: Floor }) {
  return (
    <>
      <table className="border-1 border rounded-lg border-separate border-spacing-0">
        <tbody>
          <tr>
            <td className="rounded-tl-lg border text-right bg-muted p-3">Name</td>
            <td className="rounded-tr-lg border p-3">{floor.name}</td>
          </tr>
          <tr>
            <td className="border text-right bg-muted p-3">Plan Image File Name</td>
            <td className="border p-3">{floor.planImageFileName}</td>
          </tr>
          <tr>
            <td className="border text-right bg-muted p-3">Plan Image Path</td>
            <td className="border p-3">{floor.planImagePath}</td>
          </tr>
          <tr>
            <td className="rounded-bl-lg border text-right bg-muted p-3">Order Number</td>
            <td className="rounded-br-lg border p-3">{floor.orderNumber}</td>
          </tr>
          <tr>
            <td className="rounded-bl-lg border text-right bg-muted p-3">Width</td>
            <td className="rounded-br-lg border p-3">{floor.width}</td>
          </tr>
          <tr>
            <td className="rounded-bl-lg border text-right bg-muted p-3">Height</td>
            <td className="rounded-br-lg border p-3">{floor.height}</td>
          </tr>
          <tr>
            <td className="rounded-bl-lg border text-right bg-muted p-3">Is Archived</td>
            <td className="rounded-br-lg border p-3">{floor.isArchived}</td>
          </tr>
          <tr>
            <td className="rounded-bl-lg border text-right bg-muted p-3">Floor Scale</td>
            <td className="rounded-br-lg border p-3">{floor.floorScale}</td>
          </tr>
        </tbody>
      </table>
      {floor.planImagePath && (
        <img
          src={`${import.meta.env.VITE_IMGIX_BASE_URL}/${floor.planImagePath}`}
          alt={floor.name}
          className="mt-4 max-w-[500px] border-2 border-blue-500"
        />
      )}
    </>
  )
}
