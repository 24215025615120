// types
import { ConfigProps, MenuOrientation, ThemeDirection, ThemeMode } from '@/types/config'

export const DASHBOARD_PATH = '/'
export const HORIZONTAL_MAX_ITEM = 7

const config: ConfigProps = {
  menuOrientation: MenuOrientation.VERTICAL,
  miniDrawer: false,
  fontFamily: `'Fira Sans', sans-serif`,
  borderRadius: 4,
  outlinedFilled: true,
  mode: ThemeMode.LIGHT,
  presetColor: 'default',
  i18n: 'en',
  themeDirection: ThemeDirection.LTR,
  container: false,
}

export default config
