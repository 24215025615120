import { Link } from 'react-router-dom'
import { useFavoriteDevices } from '@/lib/db'
import { Favorite } from '@/lib/types'

export default function FavoriteFavoriteDevicesTab({ favorite }: Readonly<{ favorite: Favorite }>) {
  const [favoriteDevices, loading] = useFavoriteDevices(favorite)

  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Favorite Device</th>
          </tr>
        </thead>
        <tbody>
          {favoriteDevices?.map((favoriteDevice) => {
            return (
              <tr key={favoriteDevice.id}>
                <td>
                  <Link className="hover:text-primary" to={`devices/${favoriteDevice.id}`}>
                    {favoriteDevice.name}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
