import { ContextHolder, useAuth, useLoginWithRedirect } from '@frontegg/react'
import { useEffect } from 'react'
import { FronteggAuthState, useFronteggAuthState } from './FronteggUserProvider'

export default function FronteggUserProviderWeb({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  const { user: fronteggUser, isLoading: fronteggAuthIsLoding } = useAuth()
  const { setFronteggAuth } = useFronteggAuthState()

  useEffect(() => {
    const fronteggAuth: FronteggAuthState = {
      fronteggUserId: fronteggUser?.id,
      fronteggTenantId: fronteggUser?.tenantId,
      fronteggTenantIds: fronteggUser?.tenantIds,
      fronteggAccessToken: fronteggUser?.accessToken,
      fronteggAuthIsLoading: fronteggAuthIsLoding,
    }

    console.log('FEUPWeb: setting frontegg auth from frontegg web values', fronteggAuth)

    setFronteggAuth(fronteggAuth)
  }, [fronteggAuthIsLoding, setFronteggAuth, fronteggUser])

  console.log('FEUPWeb: rendering children')
  return <>{children}</>
}

export function useLogin() {
  const loginWithRedirect = useLoginWithRedirect()

  return () => {
    console.log('FEUPWeb: loggging in with redirect')
    loginWithRedirect()
  }
}

export function useLogout() {
  return () => {
    console.log('FEUPWeb: logging out')

    // web logout
    localStorage.removeItem('TEST-USER')
    const baseUrl = ContextHolder.getContext().baseUrl
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location.origin}`
  }
}
