import { Messaging, getMessaging } from 'firebase/messaging'
import { createContext, useContext } from 'react'
import { Capacitor } from '@capacitor/core'
import { useApp } from './FirebaseProvider'

const MessagingContext = createContext<Messaging | undefined>(undefined)

// MessagingProvider is a custom provider that wraps the Firebase messaging SDK
export default function MessagingProvider({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  const firebaseApp = useApp()

  let messaging: Messaging | undefined
  if (typeof window !== 'undefined' && !Capacitor.isNativePlatform() && process.env.NODE_ENV !== 'test') {
    messaging = getMessaging(firebaseApp)
  }

  return <MessagingContext.Provider value={messaging}>{children}</MessagingContext.Provider>
}

export function useMessaging(): Messaging | undefined {
  return useContext(MessagingContext)
}
