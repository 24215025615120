import { Link } from 'react-router-dom'
import { useProjectMembers } from '@/lib/db'
import { Project } from '@/lib/types'

export default function ProjectMembersTab({ project }: Readonly<{ project: Project }>) {
  const [members, loading] = useProjectMembers(project)
  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Member</th>
          </tr>
        </thead>
        <tbody>
          {members?.map((member) => {
            return (
              <tr key={member.userId}>
                <td>
                  <Link className="hover:text-primary" to={`members/${member.userId}`}>
                    {member.userId}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
