import { Link } from 'react-router-dom'
import { useDevices } from '@/lib/db'
import { Floor } from '@/lib/types'

export default function FloorDevicesTab({ floor }: Readonly<{ floor: Floor }>) {
  const [devices, loading] = useDevices(floor)

  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Device</th>
          </tr>
        </thead>
        <tbody>
          {devices?.map((device) => {
            return (
              <tr key={device.id}>
                <td>
                  <Link className="hover:text-primary" to={`devices/${device.id}`}>
                    {device.id}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
