import ReactDOM from 'react-dom/client'
import App from './components/App'
import * as Sentry from '@sentry/capacitor'
import * as SentryReact from '@sentry/react'
import './index.css'

// style + assets
import '@/assets/scss/style.scss'

// google-fonts
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/700.css'

import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'

if (process.env.NODE_ENV !== 'development') {
  // initialize Sentry exception tracking
  Sentry.init(
    {
      dsn: 'https://c70257385ed207dd7f87d586efaeb3e3@o4507172132421632.ingest.us.sentry.io/4507172134977536',
      integrations: [
        new SentryReact.BrowserTracing(),
        new SentryReact.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // use the git commit SHA as the release version
      release: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA,
      // set the environment
      environment: import.meta.env.VITE_NODE_ENV,
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    },
    // Forward the init method from @sentry/react
    SentryReact.init,
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
