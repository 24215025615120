import { Link } from 'react-router-dom'
import { useSiteMembers } from '@/lib/db'
import { Site } from '@/lib/types'

export default function SiteMembersTab({ site }: Readonly<{ site: Site }>) {
  const [members, loading] = useSiteMembers(site)

  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Member</th>
          </tr>
        </thead>
        <tbody>
          {members?.map((member) => {
            return (
              <tr key={member.userId}>
                <td>
                  <Link className="hover:text-primary" to={`members/${member.userId}`}>
                    {member.userId}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
