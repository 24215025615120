import { Link } from 'react-router-dom'
import { useFavorites } from '@/lib/db'
import { Customer } from '@/lib/types'

export default function CustomerFavoriteDevicesTab({ customer }: Readonly<{ customer: Customer }>) {
  const [favorites, loading] = useFavorites(customer)

  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Favorite Device</th>
          </tr>
        </thead>
        <tbody>
          {favorites?.map((favorite) => {
            return (
              <tr key={favorite.id}>
                <td>
                  <Link className="hover:text-primary" to={`favorites/${favorite.id}`}>
                    {favorite.name}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
