import { Link } from 'react-router-dom'
import { useDeviceImages } from '@/lib/db'
import { Device } from '@/lib/types'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { useStorage } from '../FirebaseProvider'

export default function DeviceImagesTab({ device }: Readonly<{ device: Device }>) {
  const storage = useStorage()
  const [images, loading] = useDeviceImages(device)

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    console.log('file', file)
    if (!file) return

    const storageRef = ref(storage, file.name) // get a reference to the storage service

    // upload the file
    const uploadTask = uploadBytesResumable(storageRef, file)

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log('Upload is ' + progress + '% done')
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused')
            break
          case 'running':
            console.log('Upload is running')
            break
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL)
        })
      },
    )
  }

  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <input type="file" onChange={handleFileChange} />
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Images</th>
          </tr>
        </thead>
        <tbody>
          {images?.map((image) => {
            return (
              <tr key={image.id}>
                <td>
                  <Link className="hover:text-primary" to={`images/${image.id}`}>
                    {image.defaultViewFilePath}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
