import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import SiteBuildingsTab from './SiteBuildingsTab'
import SiteMembersTab from './SiteMembersTab'
import SiteInfoTab from './SiteInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useSite } from '@/lib/db'
import { compile } from 'path-to-regexp'
import { SiteRef } from '@/lib/types'
import { useCurrentOrg } from '../auth/UserProvider'

interface MatchParams extends SiteRef {
  tabId: string | undefined
}

export default function SitePage() {
  const currentOrg = useCurrentOrg()
  const { path, params } = useRouteMatch<MatchParams>()
  const { tabId = 'info' } = params
  const baseUrl = compile(path)({ ...params, tabId: undefined })
  const history = useHistory()

  const [site, loading] = useSite({ ...params, orgId: currentOrg.id })

  if (loading) return <></>

  if (!site) return <NotFoundPage />

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{site.name}</h1>
      <div role="tablist" className="tabs tabs-bordered md:inline-block">
        <input
          type="radio"
          name="site_tabs"
          role="tab"
          className="tab"
          value="info"
          aria-label="Info"
          onChange={() => {
            history.push(`${baseUrl}`)
          }}
          checked={tabId === 'info'}
        />
        <input
          type="radio"
          name="site_tabs"
          role="tab"
          className="tab"
          value="buildings"
          aria-label="Buildings"
          onChange={() => {
            history.push(`${baseUrl}/buildings`)
          }}
          checked={tabId === 'buildings'}
        />
        <input
          type="radio"
          name="site_tabs"
          role="tab"
          className="tab"
          value="members"
          aria-label="Members"
          onChange={() => {
            history.push(`${baseUrl}/members`)
          }}
          checked={tabId === 'members'}
        />
      </div>
      <div className="pt-10">
        <Switch>
          <Route exact path={`${baseUrl}`}>
            <SiteInfoTab site={site} />
          </Route>
          <Route exact path={`${baseUrl}/buildings`}>
            <SiteBuildingsTab site={site} />
          </Route>
          <Route exact path={`${baseUrl}/members`}>
            <SiteMembersTab site={site} />
          </Route>
        </Switch>
      </div>
    </>
  )
}
