import 'external-svg-loader'
import UserProvider from './auth/UserProvider'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import FirebaseProvider from './FirebaseProvider'
import DashboardPage from './dashboard/DashboardPage'
import NotFoundPage from './NotFoundPage'
import OrgPage from './org/OrgPage'
import CustomerPage from './customer/CustomerPage'
import SitePage from './site/SitePage'
import BuildingPage from './building/BuildingPage'
import FloorPage from './floor/FloorPage'
import DevicePage from './device/DevicePage'
import ProjectPage from './project/ProjectPage'
import ProjectFloorPage from './projectFloor/ProjectFloorPage'
import FavoriteDevicePage from './favoriteDevice/FavoriteDevicePage'
import FavoriteTaskPage from './favoriteTask/FavoriteTaskPage'
import ServiceTicketPage from './serviceTicket/ServiceTicketPage'
import FronteggUserProvider from './auth/FronteggUserProvider'
import FirebaseUserProvider from './auth/FirebaseUserProvider'
import AuthGuard from './auth/AuthGuard'
import AppUrlListener from './AppUrlListener'
import AppNotificationListener from './AppNotificationListener'
import LocationPage from './location/LocationPage'
import TaskPage from './task/TaskPage'
import FavoritePage from './favorite/FavoritePage'
import AccountsPage from './accounts/AccountsPage'
import ThemeCustomization from '@/themes'
import { ConfigProvider } from '@/contexts/ConfigContext'
import NavigationScroll from '@/layout/NavigationScroll'
import MainLayout from '@/layout/MainLayout'
import DrawerProvider from '@/contexts/DrawerProvider'

export default function App() {
  return (
    <ConfigProvider>
      <ThemeCustomization>
        <NavigationScroll>
          <FirebaseProvider>
            <FronteggUserProvider>
              <FirebaseUserProvider>
                <UserProvider>
                  <AuthGuard>
                    <Router>
                      <DrawerProvider>
                        <MainLayout>
                          <Switch>
                            <Route exact path="/" component={DashboardPage} />
                            <Route exact path="/org/:tabId?" component={OrgPage} />
                            <Route exact path="/accounts" component={AccountsPage} />
                            <Route exact path="/accounts/:customerId/:tabId?" component={CustomerPage} />
                            <Route
                              exact
                              path="/accounts/:customerId/projects/:projectId/:tabId?"
                              component={ProjectPage}
                            />
                            <Route
                              exact
                              path="/accounts/:customerId/projects/:projectId/floors/:floorId/:tabId?"
                              component={ProjectFloorPage}
                            />
                            <Route exact path="/accounts/:customerId/sites/:siteId/:tabId?" component={SitePage} />
                            <Route
                              exact
                              path="/accounts/:customerId/sites/:siteId/buildings/:buildingId/:tabId?"
                              component={BuildingPage}
                            />
                            <Route
                              exact
                              path="/accounts/:customerId/sites/:siteId/buildings/:buildingId/floors/:floorId/:tabId?"
                              component={FloorPage}
                            />
                            <Route
                              exact
                              path="/accounts/:customerId/sites/:siteId/buildings/:buildingId/floors/:floorId/locations/:locationId/:tabId?"
                              component={LocationPage}
                            />
                            <Route
                              exact
                              path="/accounts/:customerId/sites/:siteId/buildings/:buildingId/floors/:floorId/devices/:deviceId/:tabId?"
                              component={DevicePage}
                            />
                            <Route
                              exact
                              path="/accounts/:customerId/sites/:siteId/buildings/:buildingId/floors/:floorId/tasks/:taskId/:tabId?"
                              component={TaskPage}
                            />
                            <Route
                              exact
                              path="/accounts/:customerId/favorites/:favoriteId/:tabId?"
                              component={FavoritePage}
                            />
                            <Route
                              exact
                              path="/accounts/:customerId/favorites/:favoriteId/devices/:favoriteDeviceId/:tabId?"
                              component={FavoriteDevicePage}
                            />
                            <Route
                              exact
                              path="/accounts/:customerId/favorites/:favoriteId/tasks/:favoriteTaskId/:tabId?"
                              component={FavoriteTaskPage}
                            />
                            <Route
                              exact
                              path="/accounts/:customerId/service-tickets/:serviceTicketId/:tabId?"
                              component={ServiceTicketPage}
                            />
                            <Route path="*">
                              <NotFoundPage />
                            </Route>
                          </Switch>
                          <AppUrlListener />
                          <AppNotificationListener />
                        </MainLayout>
                      </DrawerProvider>
                    </Router>
                  </AuthGuard>
                </UserProvider>
              </FirebaseUserProvider>
            </FronteggUserProvider>
          </FirebaseProvider>
        </NavigationScroll>
      </ThemeCustomization>
    </ConfigProvider>
  )
}
