import { Link } from 'react-router-dom'
import { useCustomers } from '@/lib/db'
import { Org } from '@/lib/types'

export default function OrgsCustomersTab({ org }: Readonly<{ org: Org }>) {
  const [customers, loading] = useCustomers(org)

  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Customer</th>
          </tr>
        </thead>
        <tbody>
          {customers?.map((customer) => {
            return (
              <tr key={customer.id}>
                <td>
                  <Link className="hover:text-primary" to={`customers/${customer.id}`}>
                    {customer.name}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
