import { Link } from 'react-router-dom'
import { useServiceTickets } from '@/lib/db'
import { Customer, ServiceTicket } from '@/lib/types'

export default function CustomerServiceTicketsTab({ customer }: Readonly<{ customer: Customer }>) {
  const [serviceTickets, loading] = useServiceTickets(customer)

  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra">
        <thead>
          <tr>
            <th>Service Ticket</th>
          </tr>
        </thead>
        <tbody>
          {serviceTickets?.map((serviceTicket: ServiceTicket) => {
            return (
              <tr key={serviceTicket.id}>
                <td>
                  <Link className="hover:text-primary" to={`service-tickets/${serviceTicket.id}`}>
                    {serviceTicket.subject}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
