import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import ServiceTicketWatchersTab from './ServiceTicketWatchersTab'
import ServiceTicketInfoTab from './ServiceTicketInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useServiceTicket } from '@/lib/db'
import { compile } from 'path-to-regexp'
import ServiceTicketCommentsTab from './ServiceTicketCommentsTab'
import ServiceTicketFileAttachmentsTab from './ServiceTicketFilesTab'
import { ServiceTicketRef } from '@/lib/types'
import { useCurrentOrg } from '../auth/UserProvider'

interface MatchParams extends ServiceTicketRef {
  tabId: string | undefined
}

export default function ServiceTicketPage() {
  const currentOrg = useCurrentOrg()
  const { path, params } = useRouteMatch<MatchParams>()
  const { tabId = 'info' } = params
  const baseUrl = compile(path)({ ...params, tabId: undefined })
  const history = useHistory()

  const [serviceTicket, loading] = useServiceTicket({ ...params, orgId: currentOrg.id })

  if (loading) return <></>

  if (!serviceTicket) return <NotFoundPage />

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{serviceTicket.subject}</h1>
      <div role="tablist" className="tabs tabs-bordered md:inline-block">
        <input
          type="radio"
          name="serviceTicket_tabs"
          role="tab"
          className="tab"
          value="info"
          aria-label="Info"
          onChange={() => {
            history.push(`${baseUrl}`)
          }}
          checked={tabId === 'info'}
        />
        <input
          type="radio"
          name="serviceTicket_tabs"
          role="tab"
          className="tab"
          value="watchers"
          aria-label="Watchers"
          onChange={() => {
            history.push(`${baseUrl}/watchers`)
          }}
          checked={tabId === 'watchers'}
        />
        <input
          type="radio"
          name="serviceTicket_tabs"
          role="tab"
          className="tab"
          value="comments"
          aria-label="Comments"
          onChange={() => {
            history.push(`${baseUrl}/comments`)
          }}
          checked={tabId === 'comments'}
        />
        <input
          type="radio"
          name="serviceTicket_tabs"
          role="tab"
          className="tab"
          value="files"
          aria-label="Files"
          onChange={() => {
            history.push(`${baseUrl}/files`)
          }}
          checked={tabId === 'files'}
        />
      </div>
      <div className="pt-10">
        <Switch>
          <Route exact path={`${baseUrl}`}>
            <ServiceTicketInfoTab serviceTicket={serviceTicket} />
          </Route>
          <Route exact path={`${baseUrl}/watchers`}>
            <ServiceTicketWatchersTab serviceTicket={serviceTicket} />
          </Route>
          <Route exact path={`${baseUrl}/comments`}>
            <ServiceTicketCommentsTab serviceTicket={serviceTicket} />
          </Route>
          <Route exact path={`${baseUrl}/files`}>
            <ServiceTicketFileAttachmentsTab serviceTicket={serviceTicket} />
          </Route>
        </Switch>
      </div>
    </>
  )
}
