import { Link } from 'react-router-dom'
import { useServiceTicketWatchers } from '@/lib/db'
import { ServiceTicket } from '@/lib/types'

export default function ServiceTicketWatchersTab({ serviceTicket }: Readonly<{ serviceTicket: ServiceTicket }>) {
  const [watchers, loading] = useServiceTicketWatchers(serviceTicket)

  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Watcher</th>
          </tr>
        </thead>
        <tbody>
          {watchers?.map((watcher) => {
            return (
              <tr key={watcher.userId}>
                <td>
                  <Link className="hover:text-primary" to={`watchers/${watcher.userId}`}>
                    {watcher.userId}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
