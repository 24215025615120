import { Customer } from '@/lib/types'

export default function CustomerInfoTab({ customer }: { customer: Customer }) {
  return (
    <table className="border-1 border rounded-lg border-separate border-spacing-0">
      <tbody>
        <tr>
          <td className="rounded-tl-lg border text-right bg-muted p-3">Name</td>
          <td className="rounded-tr-lg border p-3">{customer.name}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Email</td>
          <td className="border p-3">{customer.email}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Phone</td>
          <td className="border p-3">{customer.phoneNumber}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">Is Active</td>
          <td className="border p-3">{customer.isActive}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Is Local Save Enabled</td>
          <td className="rounded-br-lg border p-3">{customer.settings.isLocalSaveEnabled}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Is Cabling Enabled</td>
          <td className="rounded-br-lg border p-3">{customer.settings.isCabling}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Is Live System Access Enabled</td>
          <td className="rounded-br-lg border p-3">{customer.settings.isLiveSystemAccess}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Is Local Save Enabled</td>
          <td className="rounded-br-lg border p-3">{customer.settings.isLocalSaveEnabled}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">Unlimited Plan In Reports</td>
          <td className="rounded-br-lg border p-3">{customer.settings.isUnlimitedPlansInReports}</td>
        </tr>
      </tbody>
    </table>
  )
}
