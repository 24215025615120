import { Link } from 'react-router-dom'
import { useBuildings } from '@/lib/db'
import { Site } from '@/lib/types'

export default function SiteBuildingsTab({ site }: Readonly<{ site: Site }>) {
  const [buildings, loading] = useBuildings(site)

  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>Building</th>
          </tr>
        </thead>
        <tbody>
          {buildings?.map((building) => {
            return (
              <tr key={building.id}>
                <td>
                  <Link className="hover:text-primary" to={`buildings/${building.id}`}>
                    {building.name}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
