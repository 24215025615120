import React from 'react'

const NotFoundPage = () => {
  return (
    <div className="flex-grow place-content-center  flex items-center justify-center w-full">
      <h1>404 - Page Not Found</h1>
    </div>
  )
}

export default NotFoundPage
