import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { App, URLOpenListenerEvent } from '@capacitor/app'

export default function AppUrlListener() {
  const history = useHistory()

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      console.log(`appURLOpen event with url=${event.url}`)
      // eslint-disable-next-line no-useless-escape
      const pathNameRegex = /http[s]*:\/\/[^\/]+(\/.+)/
      const matches = event.url.match(pathNameRegex)
      if (!matches || matches.length < 2) return
      console.log(`navigating to path=${matches[1]}`)
      history.push(matches[1])
    })
  }, [history])

  return null
}
