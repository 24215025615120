import z from 'zod'

export interface CustomerRef extends OrgRef {
  customerId: string
}

export const CustomerSchema = z
  .object({
    id: z.string(),
    orgId: z.string(),
    name: z.string(),
    email: z.string().optional(),
    phoneNumber: z.string().optional(),
    isActive: z.boolean(),
    createdDate: z.date().optional(),
    createdByUserId: z.string().optional(),
    updatedDate: z.date().optional(),
    updatedByUserId: z.string().optional(),
    settings: z
      .object({
        isLocalSaveEnabled: z.boolean(),
        isCabling: z.boolean(),
        defaultAssignedToUserId: z.string().optional(), // todo: what assignment is this? tickets?
        isUnlimitedPlansInReports: z.boolean(),
        isLiveSystemAccess: z.boolean(),
      })
      .strict(),
  })
  .strict()
export type Customer = z.infer<typeof CustomerSchema>

export const LicenseTypeSchema = z.enum(['trial', 'internal', 'licensed', 'unlicensed', 'non_billable'])
export type LicenseType = z.infer<typeof LicenseTypeSchema>

export interface OrgRef extends Record<string, any> {
  orgId: string
}

export const OrgSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    email: z.string().optional(),
    phoneNumber: z.string().optional(),
    isActive: z.boolean(),
    licenseType: LicenseTypeSchema,
    isMultiCustomer: z.boolean(),
    logoImagePath: z.string().optional(),
    fronteggId: z.string().optional(),
  })
  .strict()
export type Org = z.infer<typeof OrgSchema>

export const UserRoleSchema = z.enum(['admin'])
export type UserRole = z.infer<typeof UserRoleSchema>

export const NotificationPreferenceSchema = z.enum(['off', 'in_app', 'push'])
export type NotificationPreference = z.infer<typeof NotificationPreferenceSchema>

export const DashboardTabPreferenceSchema = z.enum(['design', 'support', 'install'])
export type DashboardTabPreference = z.infer<typeof DashboardTabPreferenceSchema>

export const ListOrGridPreferenceSchema = z.enum(['list', 'grid'])
export type ListOrGridPreference = z.infer<typeof ListOrGridPreferenceSchema>

export const DeliveryMethodPreferenceSchema = z.enum(['sms', 'email'])
export type DeliveryMethodPreference = z.infer<typeof DeliveryMethodPreferenceSchema>

export const MessagingRegistrationTokenSchema = z
  .object({
    token: z.string(),
    timestamp: z.date(),
  })
  .strict()
export type MessagingRegistrationToken = z.infer<typeof MessagingRegistrationTokenSchema>

export interface UserRef extends Record<string, any> {
  userId: string
}

export const UserSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    roles: z.array(UserRoleSchema),
    email: z.string(),
    lastActiveDate: z.date().optional(),
    isActive: z.boolean(),
    employer: z.string().optional(),
    cellNumber: z.string().optional(),
    workNumber: z.string().optional(),
    profileCompleted: z.boolean(),
    profileImagePath: z.string().optional(),
    createdDate: z.date().optional(),
    createdByUserId: z.string().optional(),
    customUserId: z.string().optional(),
    fronteggUserId: z.string(),
    uiPreferences: z
      .object({
        showDeviceName: z.boolean(),
        showFullSizeDeviceIcons: z.boolean(),
        showFieldOfView: z.boolean(),
        showPartNumber: z.boolean(),
        triggeredByMonitoring: z.boolean(), // todo: what is this?
        hideClosedTickets: z.boolean(),
        pageSize: z.number().optional(),
        dashboardTab: DashboardTabPreferenceSchema.optional(),
        listOrGridTab: ListOrGridPreferenceSchema.optional(), // todo: add adjective about where this is used
        showDeviceSize: z.number(),
        showPlanMarkup: z.boolean(),
        showArchivedDevices: z.boolean(),
        planLibraryListOrGridTab: ListOrGridPreferenceSchema.optional(),
        showAbbreviatedName: z.boolean(),
        showConnectionLine: z.boolean(),
        serviceTicketField: z.string().optional(),
        deliveryMethod: DeliveryMethodPreferenceSchema,
      })
      .strict(),
    notificationPreferences: z
      .object({
        ticketCreated: NotificationPreferenceSchema,
        ticketAssigned: NotificationPreferenceSchema,
        ticketClosed: NotificationPreferenceSchema,
        ticketCommented: NotificationPreferenceSchema,
        ticketUpdated: NotificationPreferenceSchema,
      })
      .strict(),
    messagingRegistrationTokens: z.array(MessagingRegistrationTokenSchema).optional(),
  })
  .strict()
export type User = z.infer<typeof UserSchema>

export const CustomerMemberLiveSiteRoleSchema = z.enum(['agent', 'manager', 'technician', 'view_all'])
export type CustomerMemberLiveSiteRole = z.infer<typeof CustomerMemberLiveSiteRoleSchema>

export const CustomerMemberProjectRoleSchema = z.enum(['lead_technician', 'manager', 'technician', 'view_all'])
export type CustomerMemberProjectRole = z.infer<typeof CustomerMemberProjectRoleSchema>

export const OrgMemberRoleSchema = z.enum([
  'administrator',
  'coordinator',
  'staff_member',
  'technician',
  'view_all',
  'manager',
  'agent',
])
export type OrgMemberRole = z.infer<typeof OrgMemberRoleSchema>

export const OrgMemberStatusSchema = z.enum(['mail_sent', 'accepted'])
export type OrgMemberStatus = z.infer<typeof OrgMemberStatusSchema>

export interface OrgMemberRef extends OrgRef {
  userId: string
}

export const OrgMemberSchema = z
  .object({
    orgId: z.string(),
    userId: z.string(),
    role: OrgMemberRoleSchema,
    status: OrgMemberStatusSchema,
    emailReport: z.boolean(),
    isDefaultCustomerAdmin: z.boolean(),
  })
  .strict()
export type OrgMember = z.infer<typeof OrgMemberSchema>

export interface CustomerMemberRef extends CustomerRef {
  userId: string
}

export const CustomerMemberSchema = z
  .object({
    orgId: z.string(),
    customerId: z.string(),
    userId: z.string(),
    liveSiteRole: CustomerMemberLiveSiteRoleSchema,
    employer: z.string().optional(),
    emailReport: z.boolean(),
    projectRole: CustomerMemberProjectRoleSchema,
    deviceAlert: z.boolean(),
    isAdmin: z.boolean(), // todo: shouldn't this be a role?
    lastActiveDate: z.date().optional(),
  })
  .strict()
export type CustomerMember = z.infer<typeof CustomerMemberSchema>

export interface SiteRef extends CustomerRef {
  siteId: string
}

export const SiteSchema = z
  .object({
    id: z.string(),
    orgId: z.string(),
    customerId: z.string(),
    name: z.string(),
    address: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    zip: z.string().optional(),
    createdDate: z.date().optional(),
    createdByUserId: z.string().optional(),
    updatedDate: z.date().optional(),
    updatedByUserId: z.string().optional(),
    notes: z.string().optional(),
    isArchived: z.boolean(),
    defaultTicketAssignedToUserId: z.string().optional(),
  })
  .strict()
export type Site = z.infer<typeof SiteSchema>

export interface SiteMemberRef extends SiteRef {
  userId: string
}

export const SiteMemberSchema = z
  .object({
    orgId: z.string(),
    customerId: z.string(),
    siteId: z.string(),
    userId: z.string(),
  })
  .strict()
export type SiteMember = z.infer<typeof SiteMemberSchema>

export interface BuildingRef extends SiteRef {
  buildingId: string
}

export const BuildingSchema = z
  .object({
    id: z.string(),
    orgId: z.string(),
    customerId: z.string(),
    siteId: z.string(),
    name: z.string(),
    address: z.string().optional(),
    createdDate: z.date().optional(),
    createdByUserId: z.string().optional(),
    updatedDate: z.date().optional(),
    updatedByUserId: z.string().optional(),
    orderNumber: z.number(),
    isArchived: z.boolean(),
  })
  .strict()
export type Building = z.infer<typeof BuildingSchema>

export interface FloorRef extends BuildingRef {
  floorId: string
}

export const FloorSchema = z
  .object({
    id: z.string(),
    orgId: z.string(),
    customerId: z.string(),
    siteId: z.string(),
    buildingId: z.string(),
    name: z.string(),
    planImageFileName: z.string().optional(),
    planImagePath: z.string(),
    orderNumber: z.number(),
    width: z.number().optional(),
    height: z.number().optional(),
    isArchived: z.boolean(),
    createdDate: z.date().optional(),
    createdByUserId: z.string().optional(),
    updatedDate: z.date().optional(),
    updatedByUserId: z.string().optional(),
    planImageUpdatedDate: z.date().optional(),
    planImageUpdatedByUserId: z.string().optional(),
    floorScale: z.number().optional(),
  })
  .strict()
export type Floor = z.infer<typeof FloorSchema>

export const EquipmentTypeCategorySchema = z
  .object({
    id: z.string(),
    name: z.string(),
    order: z.number(),
    pinColor: z.string().optional(),
  })
  .strict()
export type EquipmentTypeCategory = z.infer<typeof EquipmentTypeCategorySchema>

export const EquipmentTypeSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    iconName: z.string(),
    category: EquipmentTypeCategorySchema,
    iconUnicode: z.string(),
    abbreviatedNamePrefix: z.string().optional(),
    isHub: z.boolean(),
    isSmart: z.boolean(),
    isCoverage: z.boolean(),
  })
  .strict()
export type EquipmentType = z.infer<typeof EquipmentTypeSchema>

export const TaskTypeSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    iconName: z.string(),
    iconUnicode: z.string(),
  })
  .strict()
export type TaskType = z.infer<typeof TaskTypeSchema>

export interface FavoriteRef extends CustomerRef {
  favoriteId: string
}

export const FavoriteSchema = z
  .object({
    id: z.string(),
    orgId: z.string(),
    customerId: z.string(),
    name: z.string().optional(),
  })
  .strict()
export type Favorite = z.infer<typeof FavoriteSchema>

export const FavoriteItemSchema = z
  .object({
    id: z.string(),
    orgId: z.string(),
    customerId: z.string(),
    favoriteId: z.string(),
    deviceTypeId: z.string(),
    name: z.string().optional(),
    description: z.string().optional(),
    installHours: z.number().optional(),
    orderNumber: z.number(),
    pinColor: z.string(),
    abbreviatedName: z.string().optional(),
  })
  .strict()
export type FavoriteItem = z.infer<typeof FavoriteItemSchema>

export interface FavoriteDeviceRef extends FavoriteRef {
  favoriteDeviceId: string
}

export const FavoriteDeviceSchema = FavoriteItemSchema.extend({
  partNumber: z.string().optional(),
  dataType: z.string().optional(),
  manufacturer: z.string().optional(),
  price: z.number().optional(),
  deviceLocation: z.string().optional(),
}).strict()
export type FavoriteDevice = z.infer<typeof FavoriteDeviceSchema>

export interface FavoriteTaskRef extends FavoriteRef {
  favoriteTaskId: string
}

export const FavoriteTaskSchema = FavoriteItemSchema.extend({}).strict()
export type FavoriteTask = z.infer<typeof FavoriteTaskSchema>

export const AMTFieldViewSchema = z.enum(['photo_review', 'default'])
export type AMTFieldView = z.infer<typeof AMTFieldViewSchema>

export const ProjectFieldViewSchema = z.enum(['photo_review', 'default', 'flag_review', 'punch_list', 'completed_work'])
export type ProjectFieldView = z.infer<typeof ProjectFieldViewSchema>

export interface UserCustomerPreferencesRef extends UserRef {
  customerId: string
}

export const UserCustomerPreferencesSchema = z
  .object({
    userId: z.string(),
    customerId: z.string(),
    amtDeviceField: z.string().optional(),
    projectDeviceField: z.string().optional(),
    serviceTicketField: z.string().optional(),
    projectDevicePunchListField: z.string().optional(),
    isProjectDevicePunchList: z.boolean(),
    amtDevicePhotoReviewField: z.string().optional(),
    projectDevicePhotoReviewField: z.string().optional(),
    amtFieldView: AMTFieldViewSchema,
    projectFieldView: ProjectFieldViewSchema,
    projectDeviceCompletedWorkField: z.string().optional(),
    projectDeviceFlagReviewField: z.string().optional(),
    projectReportField: z.string().optional(),
    livesiteReportField: z.string().optional(),
  })
  .strict()
export type UserCustomerPreferences = z.infer<typeof UserCustomerPreferencesSchema>

export const PinColorPreferenceSchema = z.enum([
  'device_color',
  'device_status',
  'operational_status',
  'install_status',
])
export type PinColorPreference = z.infer<typeof PinColorPreferenceSchema>

export interface UserFloorPreferencesRef extends UserRef {
  floorId: string
}

export const UserFloorPreferencesSchema = z
  .object({
    userId: z.string(),
    floorId: z.string(),
    showDeviceSize: z.number(),
    hideLiveDevice: z.boolean(),
    pinColorPreference: PinColorPreferenceSchema.optional(),
  })
  .strict()
export type UserFloorPreferences = z.infer<typeof UserFloorPreferencesSchema>

export const UserDeviceTokenSchema = z
  .object({
    userId: z.string(),
    deviceToken: z.string(),
  })
  .strict()
export type UserDeviceToken = z.infer<typeof UserDeviceTokenSchema>

export const ServiceTicketQuoteLineItemSchema = z
  .object({
    item: z.string(),
    quantity: z.number(),
    rate: z.number(),
    amount: z.number(),
    note: z.string().optional(),
    tax: z.boolean(),
    include: z.boolean(),
    createdDate: z.date().optional(),
    createdByUserId: z.string().optional(),
    updatedDate: z.date().optional(),
    updatedByUserId: z.string().optional(),
  })
  .strict()
export type ServiceTicketQuoteLineItem = z.infer<typeof ServiceTicketQuoteLineItemSchema>

export const ServiceTicketQuoteSchema = z
  .object({
    preparedFor: z.string().optional(),
    preparedBy: z.string().optional(),
    taxPercentage: z.number(),
    createdDate: z.date().optional(),
    createdByUserId: z.string().optional(),
    updatedDate: z.date().optional(),
    updatedByUserId: z.string().optional(),
    lineItems: z.array(ServiceTicketQuoteLineItemSchema),
  })
  .strict()
export type ServiceTicketQuote = z.infer<typeof ServiceTicketQuoteSchema>

export const ServiceTicketStatusSchema = z.enum(['open', 'closed', 'in_progress'])
export type ServiceTicketStatus = z.infer<typeof ServiceTicketStatusSchema>

export const ServiceTicketPrioritySchema = z.enum(['low', 'medium', 'high'])
export type ServiceTicketPriority = z.infer<typeof ServiceTicketPrioritySchema>

export interface ServiceTicketRef extends CustomerRef {
  serviceTicketId: string
}

export const ServiceTicketSchema = z
  .object({
    id: z.string(),
    orgId: z.string(),
    customerId: z.string(),
    subject: z.string(),
    requestedByUserId: z.string(),
    status: ServiceTicketStatusSchema,
    priority: ServiceTicketPrioritySchema,
    floorId: z.string(),
    createdDate: z.date().optional(),
    createdByUserId: z.string().optional(),
    updatedDate: z.date().optional(),
    updatedByUserId: z.string().optional(),
    description: z.string().optional(),
    assignedToUserId: z.string().optional(),
    dueDate: z.date().optional(),
    closedDate: z.date().optional(),
    cost: z.number().optional(),
    isArchived: z.boolean(),
    scheduledDate: z.date().optional(),
    deviceIds: z.array(z.string()),
    quote: ServiceTicketQuoteSchema.optional(),
  })
  .strict()
export type ServiceTicket = z.infer<typeof ServiceTicketSchema>

export interface ServiceTicketWatcherRef extends ServiceTicketRef {
  userId: string
}

export const ServiceTicketWatcherSchema = z
  .object({
    orgId: z.string(),
    customerId: z.string(),
    serviceTicketId: z.string(),
    userId: z.string(),
  })
  .strict()
export type ServiceTicketWatcher = z.infer<typeof ServiceTicketWatcherSchema>

export interface ServiceTicketCommentRef extends ServiceTicketRef {
  serviceTicketCommentId: string
}

export const ServiceTicketCommentSchema = z
  .object({
    id: z.string(),
    orgId: z.string(),
    customerId: z.string(),
    serviceTicketId: z.string(),
    comment: z.string(),
    createdDate: z.date().optional(),
    createdByUserId: z.string().optional(),
    updatedDate: z.date().optional(),
    updatedByUserId: z.string().optional(),
  })
  .strict()
export type ServiceTicketComment = z.infer<typeof ServiceTicketCommentSchema>

export const FileAttachmentSchema = z
  .object({
    id: z.string(),
    fileName: z.string(),
    fileType: z.string(),
    filePath: z.string(),
    fileSize: z.number(),
    uploadedByUserId: z.string().optional(),
    uploadedDate: z.date(),
  })
  .strict()
export type FileAttachment = z.infer<typeof FileAttachmentSchema>

export interface ServiceTicketFileAttachmentRef extends ServiceTicketRef {
  serviceTicketFileAttachmentId: string
}

export const ServiceTicketFileAttachmentSchema = FileAttachmentSchema.extend({
  orgId: z.string(),
  customerId: z.string(),
  serviceTicketId: z.string(),
}).strict()
export type ServiceTicketFileAttachment = z.infer<typeof ServiceTicketFileAttachmentSchema>

export const ProjectStageSchema = z.enum(['design', 'installation'])
export type ProjectStage = z.infer<typeof ProjectStageSchema>

export const ProjectStatusSchema = z.enum(['open', 'in_progress', 'done'])
export type ProjectStatus = z.infer<typeof ProjectStatusSchema>

export interface ProjectRef extends CustomerRef {
  projectId: string
}

export const ProjectSchema = z.object({
  id: z.string(),
  orgId: z.string(),
  customerId: z.string(),
  name: z.string(),
  stage: ProjectStageSchema,
  description: z.string().optional(),
  createdDate: z.date().optional(),
  createdByUserId: z.string().optional(),
  updatedDate: z.date().optional(),
  updatedByUserId: z.string().optional(),
  startDate: z.date().optional(),
  completionDate: z.date().optional(),
  warrantyDate: z.date().optional(),
  address: z.string().optional(),
  projectManagerUserId: z.string().optional(),
  designerUserId: z.string().optional(),
  designStatus: ProjectStatusSchema,
  installationStatus: ProjectStatusSchema,
  isArchived: z.boolean(),
  isPublished: z.boolean(),
  customProjectId: z.string().optional(),
  publishedByUserId: z.string().optional(),
  publishedDate: z.date().optional(),
})
export type Project = z.infer<typeof ProjectSchema>

export const ProjectMemberRoleSchema = z.enum(['update_devices', 'view_project', 'manage_devices', 'update_project'])
export type ProjectMemberRole = z.infer<typeof ProjectMemberRoleSchema>

export interface ProjectMemberRef extends ProjectRef {
  userId: string
}

export const ProjectMemberSchema = z.object({
  orgId: z.string(),
  customerId: z.string(),
  projectId: z.string(),
  userId: z.string(),
  role: ProjectMemberRoleSchema,
})
export type ProjectMember = z.infer<typeof ProjectMemberSchema>

export const ProjectFileAttachmentSchema = FileAttachmentSchema.extend({
  orgId: z.string(),
  customerId: z.string(),
  projectId: z.string(),
})
export type ProjectFileAttachment = z.infer<typeof ProjectFileAttachmentSchema>

export interface ProjectFloorRef extends ProjectRef {
  floorId: string
}

export const ProjectFloorSchema = z.object({
  orgId: z.string(),
  customerId: z.string(),
  projectId: z.string(),
  floorId: z.string(),
  planImageName: z.string().optional(),
  planImagePath: z.string().optional(),
})
export type ProjectFloor = z.infer<typeof ProjectFloorSchema>

export const DeviceDataTypeSchema = z.enum(['analog', 'ip'])
export type DeviceDataType = z.infer<typeof DeviceDataTypeSchema>

export const DeviceLocationSchema = z.enum(['interior', 'exterior'])
export type DeviceLocation = z.infer<typeof DeviceLocationSchema>

export const DeviceStatusSchema = z.enum(['unpublished', 'published', 'removed', 'archived'])
export type DeviceStatus = z.infer<typeof DeviceStatusSchema>

export const DeviceOperationalStatusSchema = z.enum(['not_working', 'operational', 'operational_with_issue'])
export type DeviceOperationalStatus = z.infer<typeof DeviceOperationalStatusSchema>

export const DeviceInstallStatusSchema = z.enum(['installed', 'in_progress', 'planned'])
export type DeviceInstallStatus = z.infer<typeof DeviceInstallStatusSchema>

export const DeviceMonitoredStatusSchema = z.enum(['offline', 'online', 'device_not_found'])
export type DeviceMonitoredStatus = z.infer<typeof DeviceMonitoredStatusSchema>

export const DevicePrioritySchema = z.enum(['low', 'medium', 'critical'])
export type DevicePriority = z.infer<typeof DevicePrioritySchema>

export const DeviceAutoUpdateStatusSchema = z.enum(['by_monitoring_status', 'dont_auto_update'])
export type DeviceAutoUpdateStatus = z.infer<typeof DeviceAutoUpdateStatusSchema>

export const DeviceActionTypeSchema = z.enum(['install', 'remove', 'move', 'document', 'live_edit'])
export type DeviceActionType = z.infer<typeof DeviceActionTypeSchema>

export const DataSourceSchema = z.enum([
  'designowl',
  'designowl_ios',
  'designowl_android',
  'installowl',
  'installowl_android',
  'installowl_ios',
  'serviceowl_ios',
  'serviceowl_android',
  'import',
  'publish',
  'unknown',
  'web',
])
export type DataSource = z.infer<typeof DataSourceSchema>

export interface LocationRef extends FloorRef {
  locationId: string
}

export const LocationSchema = z.object({
  id: z.string(),
  orgId: z.string(),
  customerId: z.string(),
  siteId: z.string(),
  buildingId: z.string(),
  floorId: z.string(),
  projectId: z.string().optional(), // project only
  name: z.string().optional(),
  createdDate: z.date().optional(),
  createdByUserId: z.string().optional(),
  updatedDate: z.date().optional(),
  updatedByUserId: z.string().optional(),
  imageLeft: z.number(),
  imageTop: z.number(),
  primaryDeviceId: z.string().optional(),
})
export type Location = z.infer<typeof LocationSchema>

export interface DeviceRef extends FloorRef {
  deviceId: string
}

export const DeviceSchema = z.object({
  id: z.string(),
  orgId: z.string(),
  customerId: z.string(),
  siteId: z.string(),
  buildingId: z.string(),
  floorId: z.string(),
  createdDate: z.date().optional(),
  createdByUserId: z.string().optional(),
  updatedDate: z.date().optional(),
  updatedByUserId: z.string().optional(),
  status: DeviceStatusSchema,
  orderWithinLocation: z.number().optional(), // child only
  currentVersionId: z.string(),
})
export type Device = z.infer<typeof DeviceSchema>

export interface DeviceVersionRef extends DeviceRef {
  deviceVersionId: string
}

export const DeviceVersionSchema = z.object({
  id: z.string(),
  orgId: z.string(),
  customerId: z.string(),
  siteId: z.string(),
  buildingId: z.string(),
  floorId: z.string(),
  locationId: z.string(),
  deviceId: z.string(),
  uniqueId: z.string(),
  equipmentId: z.string(),
  name: z.string(),
  description: z.string().optional(),
  installHours: z.number().optional(),
  installedDate: z.date().optional(),
  createdDate: z.date().optional(),
  createdByUserId: z.string().optional(),
  updatedDate: z.date().optional(),
  updatedByUserId: z.string().optional(),
  installStatus: DeviceInstallStatusSchema,
  installNotes: z.string().optional(),
  installedByUserId: z.string().optional(),
  dueDate: z.date().optional(),
  priority: DevicePrioritySchema,
  imagesCount: z.number(),
  abbreviatedName: z.string().optional(),
  pinColor: z.string(),
  isFlagged: z.boolean(),
  flagNotes: z.string().optional(),
  flagCreatedByUserId: z.string().optional(),
  flagCreatedDate: z.date().optional(),
  createdSource: DataSourceSchema,
  updatedSource: DataSourceSchema,
  referenceDuplicateEquipmentId: z.string().optional(),
  assignedToUserId: z.string().optional(),
  projectId: z.string().optional(), // project only
  serialNumber: z.string().optional(),
  partNumber: z.string().optional(),
  dataType: DeviceDataTypeSchema.optional(),
  manufacturer: z.string().optional(),
  price: z.number().optional(),
  deviceLocation: DeviceLocationSchema.optional(),
  ipAddress: z.string().optional(),
  macAddress: z.string().optional(),
  operationalStatus: DeviceOperationalStatusSchema,
  barcode: z.string().optional(),
  height: z.number().optional(),
  deviceConnectedToDeviceId: z.string().optional(),
  coverageDirection: z.number().optional(),
  deviceWarrantyExpirationDate: z.date().optional(),
  laborWarrantyExpirationDate: z.date().optional(),
  isPublishedToAMT: z.boolean(),
  monitoredStatus: DeviceMonitoredStatusSchema.optional(),
  monitoredDate: z.date().optional(),
  monitoredDownDate: z.date().optional(),
  monitoredLiveFeed: z.boolean(),
  monitoredDeviceId: z.string().optional(),
  autoUpdateStatus: DeviceAutoUpdateStatusSchema,
  isSystemUpdate: z.boolean(),
  coverageArea: z.boolean(),
  coverageRange: z.number().optional(),
  networkSwitchLinkId: z.number().optional(),
  port: z.string().optional(),
  isHub: z.boolean(),
  cableCoordinates: z.string().optional(),
  actionType: DeviceActionTypeSchema,
})
export type DeviceVersion = z.infer<typeof DeviceVersionSchema>

export interface TaskRef extends FloorRef {
  taskId: string
}

export const TaskSchema = z.object({
  id: z.string(),
  orgId: z.string(),
  customerId: z.string(),
  siteId: z.string(),
  buildingId: z.string(),
  floorId: z.string(),
  locationId: z.string(),
  uniqueId: z.string(),
  equipmentId: z.string(),
  name: z.string(),
  description: z.string().optional(),
  installHours: z.number().optional(),
  installedDate: z.date().optional(),
  createdDate: z.date().optional(),
  createdByUserId: z.string().optional(),
  updatedDate: z.date().optional(),
  updatedByUserId: z.string().optional(),
  installStatus: DeviceInstallStatusSchema,
  installNotes: z.string().optional(),
  installedByUserId: z.string().optional(),
  dueDate: z.date().optional(),
  priority: DevicePrioritySchema,
  isArchived: z.boolean(),
  imagesCount: z.number(),
  abbreviatedName: z.string().optional(),
  pinColor: z.string(),
  isFlagged: z.boolean(),
  flagNotes: z.string().optional(),
  flagCreatedByUserId: z.string().optional(),
  flagCreatedDate: z.date().optional(),
  createdSource: DataSourceSchema,
  updatedSource: DataSourceSchema,
  referenceDuplicateEquipmentId: z.string().optional(),
  assignedToUserId: z.string().optional(),
  projectId: z.string().optional(), // project only
  orderWithinLocation: z.number().optional(), // child only
})
export type Task = z.infer<typeof TaskSchema>

export const ImageModeSchema = z.enum(['library_upload', 'unknown', 'capture', 'photo_capture'])
export type ImageMode = z.infer<typeof ImageModeSchema>

export const ImageStageSchema = z.enum(['design', 'installation', 'live_system'])
export type ImageStage = z.infer<typeof ImageStageSchema>

const ImageSchema = z.object({
  id: z.string(),
  orgId: z.string(),
  customerId: z.string(),
  siteId: z.string(),
  buildingId: z.string(),
  floorId: z.string(),
  defaultViewFilePath: z.string(),
  defaultViewFileName: z.string().optional(),
  createdDate: z.date().optional(),
  createdByUserId: z.string().optional(),
  updatedDate: z.date().optional(),
  updatedByUserId: z.string().optional(),
  createdSource: DataSourceSchema,
  mode: ImageModeSchema,
  stage: ImageStageSchema.optional(),
  uploadFileName: z.string().optional(),
  projectId: z.string().optional(), // project device only
})

export interface DeviceImageRef extends DeviceRef {
  deviceImageId: string
}

export const DeviceImageSchema = ImageSchema.extend({
  deviceId: z.string(),
  deviceVersionId: z.string(),
})
export type DeviceImage = z.infer<typeof DeviceImageSchema>

export interface TaskImageRef extends TaskRef {
  taskImageId: string
}

export const TaskImageSchema = ImageSchema.extend({
  taskId: z.string(),
})
export type TaskImage = z.infer<typeof TaskImageSchema>
