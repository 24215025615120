import { ServiceTicket } from '@/lib/types'

export default function ServiceTicketInfoTab({ serviceTicket }: { serviceTicket: ServiceTicket }) {
  return (
    <table className="border-1 border rounded-lg border-separate border-spacing-0">
      <tbody>
        <tr>
          <td className="rounded-tl-lg border text-right bg-muted p-3">subject</td>
          <td className="rounded-tr-lg border p-3">{serviceTicket.subject}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">requestedByUserId</td>
          <td className="border p-3">{serviceTicket.requestedByUserId}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">status</td>
          <td className="border p-3">{serviceTicket.status}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">priority</td>
          <td className="border p-3">{serviceTicket.priority}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">floorId</td>
          <td className="rounded-br-lg border p-3">{serviceTicket.floorId}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">description</td>
          <td className="rounded-br-lg border p-3">{serviceTicket.description}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">assignedToUserId</td>
          <td className="rounded-br-lg border p-3">{serviceTicket.assignedToUserId}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">dueDate</td>
          <td className="rounded-br-lg border p-3">{serviceTicket.dueDate?.toISOString()}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">closedDate</td>
          <td className="rounded-br-lg border p-3">{serviceTicket.closedDate?.toISOString()}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">cost</td>
          <td className="rounded-br-lg border p-3">{serviceTicket.cost}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">isArchived</td>
          <td className="rounded-br-lg border p-3">{serviceTicket.isArchived}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">scheduledDate</td>
          <td className="rounded-br-lg border p-3">{serviceTicket.scheduledDate?.toISOString()}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">deviceIds</td>
          <td className="rounded-br-lg border p-3">{serviceTicket.deviceIds}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">quote.preparedFor</td>
          <td className="rounded-br-lg border p-3">{serviceTicket.quote?.preparedFor}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">quote.preparedBy</td>
          <td className="rounded-br-lg border p-3">{serviceTicket.quote?.preparedBy}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">quote.taxPercentage</td>
          <td className="rounded-br-lg border p-3">{serviceTicket.quote?.taxPercentage}</td>
        </tr>
      </tbody>
    </table>
  )
}
