import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import DeviceImagesTab from './DeviceImagesTab'
import DeviceInfoTab from './DeviceInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useDevice } from '@/lib/db'
import { compile } from 'path-to-regexp'
import { DeviceRef } from '@/lib/types'
import { useCurrentOrg } from '../auth/UserProvider'

interface MatchParams extends DeviceRef {
  tabId: string | undefined
}

export default function DevicePage() {
  const currentOrg = useCurrentOrg()
  const { path, params } = useRouteMatch<MatchParams>()
  const { tabId = 'info' } = params
  const baseUrl = compile(path)({ ...params, tabId: undefined })
  const history = useHistory()

  const [device, loading] = useDevice({ ...params, orgId: currentOrg.id })

  if (loading) return <></>

  if (!device) return <NotFoundPage />

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{device.id}</h1>
      <div role="tablist" className="tabs tabs-bordered md:inline-block">
        <input
          type="radio"
          name="device_tabs"
          role="tab"
          className="tab"
          value="info"
          aria-label="Info"
          onChange={() => {
            history.push(`${baseUrl}`)
          }}
          checked={tabId === 'info'}
        />
        <input
          type="radio"
          name="device_tabs"
          role="tab"
          className="tab"
          value="images"
          aria-label="Images"
          onChange={() => {
            history.push(`${baseUrl}/images`)
          }}
          checked={tabId === 'images'}
        />
      </div>
      <div className="pt-10">
        <Switch>
          <Route exact path={`${baseUrl}`}>
            <DeviceInfoTab device={device} />
          </Route>
          <Route exact path={`${baseUrl}/images`}>
            <DeviceImagesTab device={device} />
          </Route>
        </Switch>
      </div>
    </>
  )
}
