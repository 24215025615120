import { useUser } from '@/components/auth/UserProvider'
import { useUserFloorPreference } from '@/lib/db'
import { Floor } from '@/lib/types'

export default function FloorPreferencesTab({ floor }: Readonly<{ floor: Floor }>) {
  const user = useUser()
  const [userFloorPreferences, loading] = useUserFloorPreference({ userId: user.id, floorId: floor.id })

  if (loading || !userFloorPreferences) return <></>

  return (
    <table className="border-1 border rounded-lg border-separate border-spacing-0">
      <tbody>
        <tr>
          <td className="border text-right bg-muted p-3">userId</td>
          <td className="border p-3">{userFloorPreferences.userId}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">showDeviceSize</td>
          <td className="border p-3">{userFloorPreferences.showDeviceSize}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">hideLiveDevice</td>
          <td className="border p-3">{userFloorPreferences.hideLiveDevice}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">pinColorPreference</td>
          <td className="border p-3">{userFloorPreferences.pinColorPreference}</td>
        </tr>
      </tbody>
    </table>
  )
}
