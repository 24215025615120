import { Capacitor } from '@capacitor/core'
import React, { useEffect } from 'react'
import { PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications'
import { httpsCallable } from 'firebase/functions'
import { getToken } from 'firebase/messaging'
import { useHistory } from 'react-router-dom'
import { useMessaging } from './MessagingProvider'
import { useFunctions } from './FirebaseProvider'

const AppNotificationListener: React.FC<any> = () => {
  const functions = useFunctions()
  const messaging = useMessaging()
  const history = useHistory()

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      console.log('initializing pushes')

      // check permission to use push notifications
      // Android will always be granted
      PushNotifications.checkPermissions().then(async (perms) => {
        let receive = perms.receive
        console.log(`checkPermissions=${receive}`)

        // if we need to prompt, then do it
        // iOS will prompt user and return if they granted permission or not
        if (perms.receive === 'prompt') {
          receive = (await PushNotifications.requestPermissions()).receive
          console.log(`requestPermissions=${receive}`)
        }

        // if still not granted, then move on
        if (receive !== 'granted') return

        // otherwise, register with Apple / Google to receive push via APNS/FCM
        console.log(`registering for pushes`)
        PushNotifications.register()
      })

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', async (token: Token) => {
        console.log(`fcm token=${token.value}`)
        if (!token.value) return
        const refreshToken = httpsCallable(functions, 'notifications-refresh_token')
        await refreshToken({ messagingRegistrationToken: token.value })
      })

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error: any) => {
        console.log('an error occurred registering for push notifications', error)
      })

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
        console.log(`push notification received`, JSON.stringify(notification))
      })

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed', (notification: any) => {
        console.log(`push notification action performed`, JSON.stringify(notification))
        const route = notification.notification.data['dl.route'] as string
        if (!route) return
        const slug = route.substring(4) // remove the starting cf:/
        if (!slug) return
        console.log(`routing to ${slug} from push notification`)
        history.push(slug)
      })
    } else if (messaging) {
      // web pushes
      console.log(`registering for tokens`)
      getToken(messaging, {
        vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
      })
        .then(async (currentToken) => {
          console.log(`currentToken=${currentToken}`)
          // todo: pass to server
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
          // ...
        })
    }
  })

  return null
}

export default AppNotificationListener
