import { createContext, useState, useContext, useEffect, useMemo } from 'react'
import { Org, User } from '@/lib/types'
import { useFirebaseAuthState } from './FirebaseUserProvider'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { FirebaseAnalytics } from '@capacitor-firebase/analytics'
import * as Sentry from '@sentry/browser'
import { useFirestore } from '../FirebaseProvider'
import { useFronteggAuthState } from './FronteggUserProvider'
import { orgConverter } from '@/lib/db'

type UserContextState = {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  user: User | undefined
  setUser: (user: User | undefined) => void
  currentOrg: Org | undefined
  setCurrentOrg: (org: Org | undefined) => void
  orgs: Org[] | undefined
  setOrgs: (orgs: Org[] | undefined) => void
}

export const UserContext = createContext<UserContextState>({
  isLoading: true,
  setIsLoading: () => {
    /* do nothing */
  },
  user: undefined,
  setUser: () => {
    /* do nothing */
  },
  currentOrg: undefined,
  setCurrentOrg: () => {
    /* do nothing */
  },
  orgs: undefined,
  setOrgs: () => {
    /* do nothing */
  },
})

export default function UserProvider({
  defaultUser = undefined,
  defaultIsLoading = true,
  children,
}: Readonly<{
  defaultUser?: User
  defaultIsLoading?: boolean
  children: React.ReactNode
}>) {
  const [isLoading, setIsLoading] = useState<boolean>(defaultIsLoading)
  const [user, setUser] = useState<User | undefined>(defaultUser)
  const [currentOrg, setCurrentOrg] = useState<Org | undefined>(undefined)
  const [orgs, setOrgs] = useState<Org[] | undefined>(undefined)
  const {
    fronteggAuth: { fronteggTenantId, fronteggTenantIds },
  } = useFronteggAuthState()
  const {
    firebaseAuth: { firebaseAuthIsLoading, firebaseUserId },
  } = useFirebaseAuthState()
  const firestore = useFirestore()

  useEffect(() => {
    console.log(`UP: useEffect firebaseAuthIsLoading=${firebaseAuthIsLoading} firebaseUserId=${firebaseUserId}`)
    if (firebaseAuthIsLoading) {
      console.log('UP: firebase user is still loading.  just wait longer')
      return
    }

    const initializeUserState = async () => {
      console.log(`UP: in initializeUserState firebaseUserId=${firebaseUserId}`)
      if (!firebaseUserId) {
        console.log(`UP: not logged in to firebase.  we are completely logged out.`)
        setUser(undefined)
        setCurrentOrg(undefined)
        setOrgs(undefined)
        setIsLoading(false)
        Sentry.setUser(null)
        await FirebaseAnalytics.setUserId({ userId: null })
        return
      }

      console.log(`UP: logged in to firebase, fetching user from firestore`)
      // otherwise query for the firestore user
      const querySnapshot = await getDocs(
        query(collection(firestore, '/users'), where('fronteggUserId', '==', firebaseUserId)),
      )

      const userFromStore = querySnapshot.docs[0] ? (querySnapshot.docs[0].data() as User) : undefined
      if (!userFromStore) throw new Error(`logged in user is missing a user record in firestore ${firebaseUserId}`)

      // set the user for GA
      await FirebaseAnalytics.setUserId({ userId: userFromStore.id })

      // set the user for Sentry
      Sentry.setUser({ id: userFromStore.id, email: userFromStore.email })

      const orgsFromStore = await getDocs(
        query(collection(firestore, '/orgs').withConverter(orgConverter), where('fronteggId', 'in', fronteggTenantIds)),
      )
      const orgs = orgsFromStore.docs.map((doc) => doc.data())

      // find the current org
      const currentOrg = orgs.find((org) => org.fronteggId === fronteggTenantId)

      console.log('UP: setting userFromStore', userFromStore)
      setUser(userFromStore)
      setCurrentOrg(currentOrg)
      setOrgs(orgs)
      setIsLoading(false)
    }

    initializeUserState()
  }, [firebaseAuthIsLoading, firebaseUserId, firestore, setIsLoading, setUser, fronteggTenantId, fronteggTenantIds])

  console.log(`UP: rendering isLoading=${isLoading} user=${user?.id}`)
  const value = useMemo(
    () => ({ isLoading, setIsLoading, user, setUser, currentOrg, setCurrentOrg, orgs, setOrgs }),
    [isLoading, user, setIsLoading, setUser, currentOrg, setCurrentOrg, orgs, setOrgs],
  )
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUserState = (): UserContextState => {
  return useContext(UserContext)
}

export const useUser = (): User => {
  const { user } = useContext(UserContext)
  if (!user) throw new Error("Can't call useUser before the user context is loaded")
  return user
}

export const useCurrentOrg = (): Org => {
  const { currentOrg } = useContext(UserContext)
  if (!currentOrg) throw new Error("Can't call useCurrentOrg before the user context is loaded")
  return currentOrg
}

export const useOrgs = (): Org[] => {
  const { orgs } = useContext(UserContext)
  if (!orgs) throw new Error("Can't call useOrgs before the user context is loaded")
  return orgs
}
