import { Link } from 'react-router-dom'
import { useServiceTicketFileAttachments } from '@/lib/db'
import { ServiceTicket } from '@/lib/types'

export default function ServiceTicketFilesTab({ serviceTicket }: Readonly<{ serviceTicket: ServiceTicket }>) {
  const [fileAttachments, loading] = useServiceTicketFileAttachments(serviceTicket)

  if (loading) return <></>

  return (
    <div className="overflow-x-auto bg-base-100">
      <table className="table table-zebra bg-base-100">
        <thead>
          <tr>
            <th>File</th>
          </tr>
        </thead>
        <tbody>
          {fileAttachments?.map((fileAttachment) => {
            return (
              <tr key={fileAttachment.id}>
                <td>
                  <Link className="hover:text-primary" to={`files/${fileAttachment.id}`}>
                    {fileAttachment.fileName}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
