import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import LocationInfoTab from './LocationInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useLocation } from '@/lib/db'
import { compile } from 'path-to-regexp'
import { LocationRef } from '@/lib/types'
import { useCurrentOrg } from '../auth/UserProvider'

interface MatchParams extends LocationRef {
  tabId: string | undefined
}

export default function LocationPage() {
  const currentOrg = useCurrentOrg()
  const { path, params } = useRouteMatch<MatchParams>()
  const { tabId = 'info' } = params
  const baseUrl = compile(path)({ ...params, tabId: undefined })
  const history = useHistory()

  const [location, loading] = useLocation({ ...params, orgId: currentOrg.id })

  if (loading) return <></>

  if (!location) return <NotFoundPage />

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">
        {location.imageLeft} x {location.imageTop}
      </h1>
      <div role="tablist" className="tabs tabs-bordered md:inline-block">
        <input
          type="radio"
          name="floor_tabs"
          role="tab"
          className="tab"
          value="info"
          aria-label="Info"
          onChange={() => {
            history.push(`${baseUrl}`)
          }}
          checked={tabId === 'info'}
        />
      </div>
      <div className="pt-10">
        <Switch>
          <Route exact path={`${baseUrl}`}>
            <LocationInfoTab location={location} />
          </Route>
        </Switch>
      </div>
    </>
  )
}
