import { useRouteMatch } from 'react-router-dom'
import CustomerSitesTab from './CustomerSitesTab'
import CustomerMembersTab from './CustomerMembersTab'
import CustomerInfoTab from './CustomerInfoTab'
import CustomerFavoritesTab from './CustomerFavoritesTab'
import CustomerPreferencesTab from './CustomerPreferencesTab'
import CustomerProjectsTab from './CustomerProjectsTab'
import CustomerServiceTicketsTab from './CustomerServiceTicketsTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useCustomer } from '@/lib/db'
import { CustomerRef } from '@/lib/types'
import { useCurrentOrg } from '../auth/UserProvider'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useState } from 'react'

interface MatchParams extends CustomerRef {
  tabId: string | undefined
}

export default function CustomerPage() {
  const currentOrg = useCurrentOrg()
  const { params } = useRouteMatch<MatchParams>()
  const [customer, loading] = useCustomer({ ...params, orgId: currentOrg.id })
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  if (loading) return <></>

  if (!customer) return <NotFoundPage />

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h6">{customer.name}</Typography>
        </Box>
      </Box>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Sites" />
          <Tab label="Projects" />
          <Tab label="Service Tickets" />
          <Tab label="Favorites" />
          <Tab label="Preferences" />
          <Tab label="Members" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <CustomerInfoTab customer={customer} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <CustomerSitesTab customer={customer} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <CustomerProjectsTab customer={customer} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <CustomerServiceTicketsTab customer={customer} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <CustomerFavoritesTab customer={customer} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <CustomerPreferencesTab customer={customer} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <CustomerMembersTab customer={customer} />
        </TabPanel>
      </Paper>
    </>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}
