import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import FloorLocationsTab from './FloorLocationsTab'
import FloorInfoTab from './FloorInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useFloor } from '@/lib/db'
import { compile } from 'path-to-regexp'
import FloorPreferencesTab from './FloorPreferencesTab'
import FloorMapTab from './FloorMapTab'
import FloorDevicesTab from './FloorDevicesTab'
import { FloorRef } from '@/lib/types'
import { XMarkIcon } from '@heroicons/react/24/outline'
import FloorTasksTab from './FloorTasksTab'
import { useCurrentOrg } from '../auth/UserProvider'

interface MatchParams extends FloorRef {
  tabId: string | undefined
}

export default function FloorPage() {
  const currentOrg = useCurrentOrg()
  const { path, params } = useRouteMatch<MatchParams>()
  const { tabId = 'info' } = params
  const baseUrl = compile(path)({ ...params, tabId: undefined })
  const history = useHistory()

  const [floor, loading] = useFloor({ ...params, orgId: currentOrg.id })

  if (loading) return <></>

  if (!floor) return <NotFoundPage />

  return (
    <div className="flex flex-col h-screen">
      <h1 className="text-2xl font-bold pb-4">{floor.name}</h1>
      <div role="tablist" className="tabs tabs-bordered md:inline-block">
        <input
          type="radio"
          name="floor_tabs"
          role="tab"
          className="tab"
          value="info"
          aria-label="Info"
          onChange={() => {
            history.push(`${baseUrl}`)
          }}
          checked={tabId === 'info'}
        />
        <input
          type="radio"
          name="floor_tabs"
          role="tab"
          className="tab"
          value="locations"
          aria-label="Locations"
          onChange={() => {
            history.push(`${baseUrl}/locations`)
          }}
          checked={tabId === 'locations'}
        />
        <input
          type="radio"
          name="floor_tabs"
          role="tab"
          className="tab"
          value="user-preferences"
          aria-label="User Preferences"
          onChange={() => {
            history.push(`${baseUrl}/user-preferences`)
          }}
          checked={tabId === 'user-preferences'}
        />
        <input
          type="radio"
          name="floor_tabs"
          role="tab"
          className="tab"
          value="devices"
          aria-label="Devices"
          onChange={() => {
            history.push(`${baseUrl}/devices`)
          }}
          checked={tabId === 'devices'}
        />
        <input
          type="radio"
          name="floor_tabs"
          role="tab"
          className="tab"
          value="tasks"
          aria-label="Tasks"
          onChange={() => {
            history.push(`${baseUrl}/tasks`)
          }}
          checked={tabId === 'tasks'}
        />
        <input
          type="radio"
          name="floor_tabs"
          role="tab"
          className="tab"
          value="map"
          aria-label="Map"
          onChange={() => {
            ;(document.getElementById('map_modal') as HTMLDialogElement)?.showModal()
          }}
          checked={tabId === 'map'}
        />
      </div>
      <div className="pt-10">
        <Switch>
          <Route exact path={`${baseUrl}`}>
            <FloorInfoTab floor={floor} />
          </Route>
          <Route exact path={`${baseUrl}/locations`}>
            <FloorLocationsTab floor={floor} />
          </Route>
          <Route exact path={`${baseUrl}/user-preferences`}>
            <FloorPreferencesTab floor={floor} />
          </Route>
          <Route exact path={`${baseUrl}/devices`}>
            <FloorDevicesTab floor={floor} />
          </Route>
          <Route exact path={`${baseUrl}/tasks`}>
            <FloorTasksTab floor={floor} />
          </Route>
        </Switch>
      </div>
      <dialog id="map_modal" className="modal h-screen ">
        <div className="modal-box h-screen relative max-w-none w-full p-0">
          <FloorMapTab floor={floor} />
          <button
            className="absolute top-0 right-0 p-4"
            onClick={() => {
              ;(document.getElementById('map_modal') as HTMLDialogElement)?.close()
            }}
          >
            <XMarkIcon className="h-6 w-6 text-gray-500" />
          </button>
        </div>
      </dialog>
    </div>
  )
}
