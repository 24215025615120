import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import FavoriteInfoTab from './FavoriteInfoTab'
import FavoriteFavoriteDevicesTab from './FavoriteDevicesTab'
import FavoriteFavoriteTasksTab from './FavoriteTasksTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useFavorite } from '@/lib/db'
import { compile } from 'path-to-regexp'
import { FavoriteRef } from '@/lib/types'

interface MatchParams extends FavoriteRef {
  tabId: string | undefined
}

export default function FavoritePage() {
  const { path, params } = useRouteMatch<MatchParams>()
  const { tabId = 'info' } = params
  const baseUrl = compile(path)({ ...params, tabId: undefined })
  const history = useHistory()

  const [favorite, loading] = useFavorite(params)

  if (loading) return <></>

  if (!favorite) return <NotFoundPage />

  return (
    <>
      <h1 className="text-2xl font-bold pb-4">{favorite.name}</h1>
      <div role="tablist" className="tabs tabs-bordered md:inline-block">
        <input
          type="radio"
          name="favorite_tabs"
          role="tab"
          className="tab"
          value="info"
          aria-label="Info"
          onChange={() => {
            history.push(`${baseUrl}`)
          }}
          checked={tabId === 'info'}
        />
        <input
          type="radio"
          name="favorite_tabs"
          role="tab"
          className="tab"
          value="devices"
          aria-label="Favorite Devices"
          onChange={() => {
            history.push(`${baseUrl}/devices`)
          }}
          checked={tabId === 'devices'}
        />
        <input
          type="radio"
          name="favorite_tabs"
          role="tab"
          className="tab"
          value="tasks"
          aria-label="Favorite Tasks"
          onChange={() => {
            history.push(`${baseUrl}/tasks`)
          }}
          checked={tabId === 'tasks'}
        />
      </div>
      <div className="pt-10">
        <Switch>
          <Route exact path={`${baseUrl}`}>
            <FavoriteInfoTab favorite={favorite} />
          </Route>
          <Route exact path={`${baseUrl}/devices`}>
            <FavoriteFavoriteDevicesTab favorite={favorite} />
          </Route>
          <Route exact path={`${baseUrl}/tasks`}>
            <FavoriteFavoriteTasksTab favorite={favorite} />
          </Route>
        </Switch>
      </div>
    </>
  )
}
