import { useUser } from '@/components/auth/UserProvider'
import { useUserCustomerPreference } from '@/lib/db'
import { Customer } from '@/lib/types'

export default function CustomerPreferencesTab({ customer }: Readonly<{ customer: Customer }>) {
  const user = useUser()
  const [userCustomerPreferences, loading] = useUserCustomerPreference({
    userId: user.id,
    customerId: customer.id,
  })

  if (loading || !userCustomerPreferences) return <></>

  return (
    <table className="border-1 border rounded-lg border-separate border-spacing-0">
      <tbody>
        <tr>
          <td className="border text-right bg-muted p-3">userId</td>
          <td className="border p-3">{userCustomerPreferences.userId}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">amtDeviceField</td>
          <td className="border p-3">{userCustomerPreferences.amtDeviceField}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">projectDeviceField</td>
          <td className="border p-3">{userCustomerPreferences.projectDeviceField}</td>
        </tr>
        <tr>
          <td className="border text-right bg-muted p-3">serviceTicketField</td>
          <td className="border p-3">{userCustomerPreferences.serviceTicketField}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">projectDevicePunchListField</td>
          <td className="rounded-br-lg border p-3">{userCustomerPreferences.projectDevicePunchListField}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">isProjectDevicePunchList</td>
          <td className="rounded-br-lg border p-3">{userCustomerPreferences.isProjectDevicePunchList}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">amtDevicePhotoReviewField</td>
          <td className="rounded-br-lg border p-3">{userCustomerPreferences.amtDevicePhotoReviewField}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">amtFieldView</td>
          <td className="rounded-br-lg border p-3">{userCustomerPreferences.amtFieldView}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">projectFieldView</td>
          <td className="rounded-br-lg border p-3">{userCustomerPreferences.projectFieldView}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">projectDeviceCompletedWorkField</td>
          <td className="rounded-br-lg border p-3">{userCustomerPreferences.projectDeviceCompletedWorkField}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">projectDeviceFlagReviewField</td>
          <td className="rounded-br-lg border p-3">{userCustomerPreferences.projectDeviceFlagReviewField}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">projectReportField</td>
          <td className="rounded-br-lg border p-3">{userCustomerPreferences.projectReportField}</td>
        </tr>
        <tr>
          <td className="rounded-bl-lg border text-right bg-muted p-3">livesiteReportField</td>
          <td className="rounded-br-lg border p-3">{userCustomerPreferences.livesiteReportField}</td>
        </tr>
      </tbody>
    </table>
  )
}
