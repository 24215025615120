import React, { createContext, useContext, useState } from 'react'

interface DrawerContextProps {
  drawerOpen: boolean
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const DrawerContext = createContext<DrawerContextProps>({
  drawerOpen: false,
  setDrawerOpen: () => {
    /* empty */
  },
})

export default function DrawerProvider({ children }: { children: React.ReactNode }) {
  const [drawerOpen, setDrawerOpen] = useState(false)

  return <DrawerContext.Provider value={{ drawerOpen, setDrawerOpen }}>{children}</DrawerContext.Provider>
}

export function useDrawerState() {
  return useContext(DrawerContext)
}
